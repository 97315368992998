import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme
} from '@mui/material';
import { Today as TodayIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import moment from 'moment';

const ActionItemsCalendar = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const { items: actionItems, loading } = useSelector((state) => state.actionItems);
  const [overdue, setOverdue] = useState([]);
  const [dueToday, setDueToday] = useState([]);
  const [dueTomorrow, setDueTomorrow] = useState([]);
  const [upcomingWeek, setUpcomingWeek] = useState([]);

  useEffect(() => {
    const now = moment();
    const today = now.startOf('day');
    const tomorrow = moment(today).add(1, 'days');
    const weekLater = moment(today).add(7, 'days');

    const categorizedItems = actionItems.reduce((acc, item) => {
      if (!item.dueDate) return acc;

      const dueDate = moment(item.dueDate); // dueDate is now an ISO string
      
      if (dueDate.isBefore(today)) {
        acc.overdue.push(item);
      } else if (dueDate.isSame(today, 'day')) {
        acc.today.push(item);
      } else if (dueDate.isSame(tomorrow, 'day')) {
        acc.tomorrow.push(item);
      } else if (dueDate.isBefore(weekLater)) {
        acc.week.push(item);
      }
      
      return acc;
    }, { overdue: [], today: [], tomorrow: [], week: [] });

    setOverdue(categorizedItems.overdue);
    setDueToday(categorizedItems.today);
    setDueTomorrow(categorizedItems.tomorrow);
    setUpcomingWeek(categorizedItems.week);
  }, [actionItems]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'action-items-popover' : undefined;

  const renderSection = (title, items, color) => {
    if (!items || items.length === 0) return null;

    return (
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{
            color: color,
            fontWeight: 'bold',
            mb: 1
          }}
        >
          {title} ({items.length})
        </Typography>
        <List dense>
          {items.map((item) => (
            <ListItem key={item.id}>
              <ListItemText
                primary={item.title}
                secondary={moment(item.dueDate).format('MMM D, YYYY')}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  const notificationCount = overdue.length + dueToday.length;

  const buttonContent = (
    <Box sx={{ position: 'relative' }}>
      <TodayIcon />
      {notificationCount > 0 && (
        <Box
          sx={{
            position: 'absolute',
            top: -8,
            right: -8,
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            borderRadius: '50%',
            width: 20,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.75rem',
          }}
        >
          {notificationCount}
        </Box>
      )}
    </Box>
  );

  if (loading) {
    return <Typography>Loading action items...</Typography>;
  }

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        color="inherit"
      >
        {buttonContent}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2, maxWidth: 400, maxHeight: 400, overflow: 'auto' }}>
          {renderSection('Overdue', overdue, theme.palette.error.main)}
          {renderSection('Due Today', dueToday, theme.palette.warning.main)}
          {renderSection('Due Tomorrow', dueTomorrow, theme.palette.info.main)}
          {renderSection('Upcoming Week', upcomingWeek, theme.palette.text.secondary)}
          
          {actionItems.length === 0 && (
            <Typography variant="body2" color="textSecondary">
              No action items found
            </Typography>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default ActionItemsCalendar;
