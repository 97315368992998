import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactMarkdown from 'react-markdown';

const LeadProfile = ({ open, onClose, profile, loading }) => {
  const [expanded, setExpanded] = useState(false);

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  const prompts = {
    company: profile?.prompts?.companyPrompt,
    contact: profile?.prompts?.contactPrompt
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Company & Contact Profile
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 4 }}>
          <Box sx={{ 
            '& h1': { 
              fontSize: '1.5rem',
              fontWeight: 600,
              color: (theme) => theme.palette.primary.main,
              mb: 2
            },
            '& h2': {
              fontSize: '1.25rem',
              fontWeight: 500,
              color: (theme) => theme.palette.text.primary,
              mt: 3,
              mb: 2
            },
            '& p': {
              mb: 1.5,
              color: (theme) => theme.palette.text.secondary
            },
            '& ul, & ol': {
              pl: 3,
              mb: 2
            },
            '& li': {
              mb: 0.5
            },
            '& hr': {
              my: 2,
              borderColor: (theme) => theme.palette.divider
            }
          }}>
            <ReactMarkdown>
              {typeof profile?.company === 'string' 
                ? profile.company 
                : typeof profile?.company === 'object' 
                  ? JSON.stringify(profile.company, null, 2)
                  : ''}
            </ReactMarkdown>
            <Box sx={{ mt: 4 }}>
              <ReactMarkdown>
                {typeof profile?.contact === 'string'
                  ? profile.contact
                  : typeof profile?.contact === 'object'
                    ? JSON.stringify(profile.contact, null, 2)
                    : ''}
              </ReactMarkdown>
            </Box>
          </Box>
        </Box>

        {prompts && (prompts.company || prompts.contact) && (
          <Box sx={{ mt: 2 }}>
            <Accordion 
              expanded={expanded} 
              onChange={() => setExpanded(!expanded)}
              sx={{ 
                '&:before': { display: 'none' },
                boxShadow: 'none',
                border: '1px solid',
                borderColor: 'grey.200',
                borderRadius: '4px !important',
                mb: 2
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: 'grey.50',
                  '& .MuiAccordionSummary-content': {
                    margin: '12px 0'
                  }
                }}
              >
                <Typography variant="subtitle1" color="primary">
                  View Generation Prompts
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'grid', gap: 2, py: 1 }}>
                  {prompts.company && (
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Company Profile Prompt
                      </Typography>
                      <Box sx={{ 
                        p: 2, 
                        bgcolor: 'grey.50', 
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: 'grey.200'
                      }}>
                        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                          {prompts.company}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {prompts.contact && (
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Contact Profile Prompt
                      </Typography>
                      <Box sx={{ 
                        p: 2, 
                        bgcolor: 'grey.50', 
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: 'grey.200'
                      }}>
                        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                          {prompts.contact}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LeadProfile;
