import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
import ScheduleModal from './ScheduleModal';
import '../../../Styles/Centerdetails.css';

const ShortlistTable = ({ 
    proposal, 
    proposalId, 
    shortlistedCenters, 
    schedules 
}) => {
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [selectedCenter, setSelectedCenter] = useState(null);

    const formatPrice = (price) => {
        if (!price) return 'N/A';
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0
        }).format(price);
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp || !timestamp.seconds) return '';
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleString('en-IN', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const handleScheduleClick = (centerId) => {
        setSelectedCenter(centerId);
        setShowScheduleModal(true);
    };

    // Filter proposed options to show only shortlisted centers
    const shortlistedOptions = proposal?.proposedOptions?.filter(
        item => shortlistedCenters[item.centerName]?.length > 0
    );

    if (!shortlistedOptions || shortlistedOptions.length === 0) {
        return (
            <div className="no-shortlists">
            </div>
        );
    }

    return (
        <div className="shortlist-summary container">
            <h1 className="display-4 text-start mb-4">Shortlisted Locations</h1>
            <div className="table-responsive">
                <table className="table shortlist-table">
                    <thead>
                        <tr>
                            <th>Photo</th>
                            <th>Space name</th>
                            <th>Price per desk</th>
                            <th>Location</th>
                            <th>Billable Seats</th>
                            <th>Total rent per month</th>
                            <th>Shortlisted By</th>
                            <th>Visit Schedule</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shortlistedOptions.map((item) => {
                            // Find the schedule for this center
                            const centerSchedule = schedules.find(
                                schedule => schedule.inventoryId === item.centerName
                            );

                            return (
                                <tr key={item.id}>
                                    <td>
                                        <img
                                            src={item.photos[0].url}
                                            alt={item.photos[0].label}
                                            className="shortlist-photo"
                                        />
                                    </td>
                                    <td>
                                        <a href={`#${item.centerName}`}>
                                            <span className="space-name">{item.centerName}</span>
                                        </a>
                                    </td>
                                    <td>{formatPrice(item.pricePerSeat)}</td>
                                    <td>{item.location.address}</td>
                                    <td>{item.billableSeats}</td>
                                    <td>{formatPrice(item?.totalRentPerMont)}</td>
                                    <td>
                                        <div className="shortlisted-by">
                                            {shortlistedCenters[item.centerName]?.map((user) => (
                                                <div key={`${item.centerName}-${user.email}-${user.timeStamp?.seconds}`}>
                                                    <Tooltip 
                                                        title={`Shortlisted on: ${formatTimestamp(user.timeStamp)}`}
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <span className="email-tag">{user.email}</span>
                                                    </Tooltip>
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="schedule-cell">
                                            {centerSchedule && (
                                                <div className="current-schedule mb-2">
                                                    <Tooltip 
                                                        title={`Scheduled on: ${formatTimestamp(centerSchedule.scheduledAt)}`}
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <small>
                                                            <strong>Scheduled For:</strong><br />
                                                            <div className="mb-1">
                                                                {new Date(centerSchedule.scheduledFor.seconds * 1000).toLocaleDateString()} at{' '}
                                                                {new Date(centerSchedule.scheduledFor.seconds * 1000).toLocaleTimeString([], {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit'
                                                                })}
                                                            </div>
                                                        </small>
                                                    </Tooltip>
                                                </div>
                                            )}
                                            <Button
                                                variant={centerSchedule ? "outline-success" : "success"}
                                                size="sm"
                                                onClick={() => handleScheduleClick(item.centerName)}
                                            >
                                                {centerSchedule ? 'Edit' : 'Schedule'}
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <ScheduleModal
                show={showScheduleModal}
                handleClose={() => setShowScheduleModal(false)}
                centerId={selectedCenter}
                proposalId={proposalId}
                currentSchedule={schedules.find(schedule => schedule.inventoryId === selectedCenter)}
            />
        </div>
    );
};

export default ShortlistTable;