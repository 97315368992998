import React, { useState, useEffect } from "react";
import "../../../Styles/Recommendedspaces.css";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Tabs,
  Tab,
  Tooltip,
  Popover,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Paper
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NoteIcon from '@mui/icons-material/Note';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import NotesIcon from '@mui/icons-material/Notes';
import AddTaskIcon from '@mui/icons-material/AddTask';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SendIcon from '@mui/icons-material/Send';
import EditCenterDialog from '../../Private/EditCenterDialog/EditCenterDialog';
import SearchCenterDialog from '../../Private/SearchCenterDialog/SearchCenterDialog';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from "./StrictModeDroppable";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { 
  addActionItem, 
  updateActionItem, 
  deleteActionItem, 
  setupActionItemsListener,
  fetchLeads,
  fetchProposals,
  saveQuestionAnswer
} from '../../../Services/FirebaseUtils';
import { Timestamp } from 'firebase/firestore';
import { auth} from '../../../firebase/config';
import { useSelector } from 'react-redux';

const Recommendedspaces = ({
  proposal,
  isEditable,
  onSaveCenter,
  onDeleteCenter,
  onAddToProposal,
  onUpdateProposal,
  selectedTab,
  onTabChange
}) => {
  const [editingCenter, setEditingCenter] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [targetCenter, setTargetCenter] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState(null);
  const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);
  const [isQuestionsDialogOpen, setIsQuestionsDialogOpen] = useState(false);
  const [notesAnchorEl, setNotesAnchorEl] = useState(null);
  const [questionsAnchorEl, setQuestionsAnchorEl] = useState(null);
  const [selectedCenterId, setSelectedCenterId] = useState(null);
  const [actionItemDialogOpen, setActionItemDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [actionItem, setActionItem] = useState({
    title: '',
    description: '',
    dueDate: null,
    status: 'pending',
    type: '',
    itemId: '',
    centerName: '',
    assignedTo: ''
  });
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [linkType, setLinkType] = useState('');
  const [leads, setLeads] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    assignedTo: '',
    dueDate: null,
    leadId: '',
    leadName: '',
    proposalId: proposal?.id || '', // Get proposal ID from props
    clientName: proposal?.clientName || '', // Get client name from props
    status: 'open'
  });
  const [answerDialogOpen, setAnswerDialogOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answer, setAnswer] = useState('');
  const [answerError, setAnswerError] = useState('');
  const [inlineAnswers, setInlineAnswers] = useState({});
  const [savingAnswers, setSavingAnswers] = useState({});
  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    if (targetCenter) {
      const element = document.getElementById(`center-details-${targetCenter}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      setTargetCenter(null);
    }
  }, [targetCenter]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [leadsResult, proposalsResult] = await Promise.all([
          fetchLeads(),
          fetchProposals()
        ]);

        if (leadsResult.success) {
          setLeads(leadsResult.leads);
        }

        if (proposalsResult.success) {
          setProposals(proposalsResult.proposals);
        }
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, []);

  const formatPrice = (price) =>
    price !== undefined && price !== null
      ? new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }).format(price)
      : "N/A";

  const handleAddNewOption = () => {
    setSearchDialogOpen(true);
  };

  const handleCloseSearchDialog = () => {
    setSearchDialogOpen(false);
  };

  const handleEditCenter = (index) => {
    setEditingCenter(proposal.proposedOptions[index]);
    setSelectedIndex(index);
    setEditDialogOpen(true);
  };

  const handleSaveCenter = async (updatedCenter) => {
    if (onSaveCenter) {
      await onSaveCenter(updatedCenter, selectedIndex);
      setEditDialogOpen(false);
    }
  };

  const handleDeleteCenter = async (center) => {
    if (onDeleteCenter) {
      await onDeleteCenter(center);
      setEditDialogOpen(false);
    }
  };

  const handleCenterClick = (centerName) => {
    setTargetCenter(centerName);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    const reorderedItem = currentOptions[sourceIndex];
    const newOrder = Array.from(proposal.proposedOptions);

    // Find the actual indices in the full array
    const oldIndex = proposal.proposedOptions.findIndex(item => item.centerName === reorderedItem.centerName);
    const newIndex = proposal.proposedOptions.findIndex(item => item.centerName === currentOptions[destinationIndex].centerName);

    newOrder.splice(oldIndex, 1);
    newOrder.splice(newIndex, 0, reorderedItem);

    if (onUpdateProposal) {
      onUpdateProposal({
        ...proposal,
        proposedOptions: newOrder,
      });
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate ? timestamp.toDate() : timestamp;
    return moment(date).format('MMM D, YYYY');
  };
  const formatNoteText = (note) => {
    const email = note.notesBy[0]?.email || 'Anonymous';
    const centerName = note.inventoryId || 'Unknown Center';
    const timestamp = note.notesBy[0]?.timestamp
      ? formatDate(note.notesBy[0].timestamp)
      : 'N/A';

    return `${email} given notes on ${centerName}\n${timestamp}`;
  };

  const getAllNotes = () => {
    // Collect notes from all options in the current proposal
    const allNotes = [];

    // Collect notes from recommended options
    recommendedOptions.forEach(option => {
      const centerNotes = proposal?.notes?.filter(note =>
        note?.inventoryId === option.centerName
      ) || [];

      allNotes.push(...centerNotes);
    });

    // Collect notes from other options
    otherOptions.forEach(option => {
      const centerNotes = proposal?.notes?.filter(note =>
        note?.inventoryId === option.centerName
      ) || [];

      allNotes.push(...centerNotes);
    });

    return allNotes;
  };

  const formatQuestionText = (feedback) => {
    const email = feedback.feedbackBy[0]?.email || 'Anonymous';
    const centerName = feedback.inventoryId || 'Unknown Center';
    const timestamp = feedback.feedbackBy[0]?.timestamp
      ? formatDate(feedback.feedbackBy[0].timestamp)
      : 'N/A';

    return `${email} asked a question about ${centerName}\n${timestamp}`;
  };

  const getAllQuestions = () => {
    // Collect feedback/questions from all options in the current proposal
    const allQuestions = [];

    // Collect questions from recommended options
    recommendedOptions.forEach(option => {
      const centerQuestions = proposal?.feedback?.filter(feedback =>
        feedback?.inventoryId === option.centerName
      ) || [];

      allQuestions.push(...centerQuestions);
    });

    // Collect questions from other options
    otherOptions.forEach(option => {
      const centerQuestions = proposal?.feedback?.filter(feedback =>
        feedback?.inventoryId === option.centerName
      ) || [];

      allQuestions.push(...centerQuestions);
    });

    return allQuestions;
  };

  const getNotesForCenter = (centerId) => {
    return proposal?.notes?.filter(note => note?.inventoryId === centerId) || [];
  };

  const getQuestionsForCenter = (centerId) => {
    return proposal?.feedback?.filter(feedback => feedback?.inventoryId === centerId) || [];
  };

  const handleOpenNotesDialog = (centerId, event) => {
    setNotesAnchorEl(event.currentTarget);
    setSelectedCenterId(centerId);
    setSelectedNotes({centerId:centerId,notes:getNotesForCenter(centerId)});
  };

  const handleOpenQuestionsDialog = (centerId, event) => {
    setQuestionsAnchorEl(event.currentTarget);
    setSelectedCenterId(centerId);
    setSelectedQuestions({centerId:centerId,questions:getQuestionsForCenter(centerId)});
  };

  const handleAddActionItem = (item, type) => {
    setFormData({
      title: item.inventoryId + " - " + item[type === 'note' ? 'notesBy' : 'feedbackItem'][0]?.text || '',
      description: type + " - asked by " + item[type === 'note' ? 'notesBy' : 'feedbackItem'][0]?.email + " on "  + formatDate(item[type === 'note' ? 'notesBy' : 'feedbackItem'][0]?.timestamp),
      assignedTo: 'user',
      dueDate: null,
      leadId: '',
      leadName: '',
      proposalId: proposal?.id || '',
      clientName: proposal?.clientName || '',
      status: 'open'
    });
    setOpenAddDialog(true);
    setIsEditMode(false);
  };

  const handleIconClick = (item, type) => {
    handleAddActionItem(item, type);
  };

  const renderNotesList = () => {
    if (!selectedNotes || selectedNotes.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="body1" color="text.secondary">
            No notes available for this center
          </Typography>
        </Box>
      );
    }

    return (
      <List>
        {selectedNotes.map((note, index) => (
          <ListItem
            key={index}
            divider
            secondaryAction={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton
                  edge="end"
                  aria-label="add action item"
                  onClick={() => handleIconClick(note, 'note')}
                  sx={{ 
                    color: 'primary.main',
                    bgcolor: 'primary.lighter',
                    '&:hover': {
                      bgcolor: 'primary.light'
                    }
                  }}
                >
                  <AddTaskIcon />
                </IconButton>
              </Box>
            }
          >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="body1">
                  {note.notesBy[0]?.text}
                </Typography>
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body1" color="textSecondary">
                {note.notesBy[0]?.email || 'Anonymous'}
              </Typography>
                <Typography variant="caption" color="textSecondary">
                  {formatDate(note.notesBy[0]?.timestamp)}
                </Typography>
                
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    );
  };

  const handleSaveInlineAnswer = async (questionIndex,centerId) => {
    if (!inlineAnswers[questionIndex]?.trim()) {
      return;
    }

    setSavingAnswers(prev => ({ ...prev, [questionIndex]: true }));
    try {
      const result = await saveQuestionAnswer(
        proposal.id,
        questionIndex,
        inlineAnswers[questionIndex].trim(),
        user.email,
        centerId
      );

      if (result.success) {
        setInlineAnswers(prev => ({ ...prev, [questionIndex]: '' }));
        // Optionally refresh the proposal data here if needed
      }
    } catch (error) {
      console.error('Error saving answer:', error);
    } finally {
      setSavingAnswers(prev => ({ ...prev, [questionIndex]: false }));
    }
  };

  const renderQuestionsList = () => {
    if (!selectedQuestions || !selectedQuestions.questions) {
      return (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="body1" color="text.secondary">
            No questions available for this center
          </Typography>
        </Box>
      );
    }

    return (
      <List sx={{ width: '100%' }}>
        {selectedQuestions.questions[0].feedbackItem.map((question, index) => (
          <ListItem
            key={index}
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              py: 2
            }}
          >
            {/* Question Section */}
            <Box sx={{ width: '100%', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                <QuestionAnswerIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {question.text}
                </Typography>
              </Box>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                ml: 4.5
              }}>
                <Typography variant="caption" color="text.secondary">
                  Asked by {question.email || 'Anonymous'}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {formatDate(question.timestamp)}
                </Typography>
              </Box>
            </Box>

            {/* Answer Section */}
            <Box sx={{ width: '90%', ml: 4.5 }}>
              {question.answer ? (
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 2,
                    bgcolor: 'success.lighter',
                    borderLeft: '3px solid',
                    borderColor: 'success.main'
                  }}
                >
                  <Typography variant="subtitle2" color="success.dark" gutterBottom>
                    Answer:
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ color: 'text.primary' }}>
                    {question.answer}
                  </Typography>
                  {question.answeredBy && (
                    <Typography variant="caption" color="text.secondary">
                      Answered by {question.answeredBy.email} on {formatDate(question.answeredBy.timestamp)}
                    </Typography>
                  )}
                </Paper>
              ) : (
                <Box sx={{ width: '100%' }}>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    placeholder="Type your answer here..."
                    value={inlineAnswers[index] || ''}
                    onChange={(e) => setInlineAnswers(prev => ({ 
                      ...prev, 
                      [index]: e.target.value 
                    }))}
                    variant="outlined"
                    sx={{ mb: 1 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveInlineAnswer(index, selectedQuestions.centerId)}
                    disabled={!inlineAnswers[index]?.trim() || savingAnswers[index]}
                    startIcon={savingAnswers[index] ? <CircularProgress size={20} /> : <SendIcon />}
                  >
                    {savingAnswers[index] ? 'Saving...' : 'Post Answer'}
                  </Button>
                </Box>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
    );
  };

  const handleFormChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dueDate: date ? Timestamp.fromDate(date.toDate()) : null
    });
  };

  const handleLinkTypeChange = (event) => {
    const type = event.target.value;
    setLinkType(type);
    setFormData({
      ...formData,
      leadId: '',
      leadName: '',
      proposalId: '',
      clientName: ''
    });
  };

  const handleLinkedItemChange = (event) => {
    const selectedId = event.target.value;
    
    if (linkType === 'lead') {
      const selectedLead = leads.find(lead => lead.id === selectedId);
      if (selectedLead) {
        setFormData({
          ...formData,
          leadId: selectedLead.id,
          leadName: selectedLead.name || selectedLead.companyName || 'Unnamed Lead'
        });
      }
    } else if (linkType === 'proposal') {
      const selectedProposal = proposals.find(proposal => proposal.id === selectedId);
      if (selectedProposal) {
        setFormData({
          ...formData,
          proposalId: selectedProposal.id,
          clientName: selectedProposal.clientName || 'Unnamed Client'
        });
      }
    }
  };

 

  const handleAddDialogClose = () => {
    setOpenAddDialog(false);
    setFormData({
      title: '',
      description: '',
      assignedTo: '',
      dueDate: null,
      leadId: '',
      leadName: '',
      proposalId: '',
      clientName: '',
      status: 'open'
    });
    setLinkType('');
    setIsEditMode(false);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      const data = {
        ...formData,
        updatedAt: Timestamp.now()
      };

      if (isEditMode) {
        await updateActionItem(data.id, data);
      } else {
        await addActionItem({
          ...data,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          status: 'open',
          dueDate: data.dueDate ? Timestamp.fromDate(data.dueDate.toDate()) : null
        });
      }
      handleAddDialogClose();
    } catch (error) {
      console.error('Error saving action item:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleAnswerQuestion = async () => {
    if (!answer.trim()) {
      setAnswerError('Answer cannot be empty');
      return;
    }

    try {
      const result = await saveQuestionAnswer(
        proposal.id,
        selectedQuestion.id,
        answer.trim(),
        proposal.email
      );

      if (result.success) {
        setAnswerDialogOpen(false);
        setAnswer('');
        setSelectedQuestion(null);
        setAnswerError('');
        // Optionally refresh the proposal data here if needed
      } else {
        setAnswerError(result.error || 'Failed to save answer');
      }
    } catch (error) {
      console.error('Error saving answer:', error);
      setAnswerError('Failed to save answer');
    }
  };

  const renderActionItemDialog = () => {
    const currentProposalName = proposal?.clientName || proposal?.name || 'Unnamed Client';

    return (
      <Dialog open={openAddDialog} onClose={handleAddDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>{isEditMode ? 'Edit Action Item' : 'Add Action Item'}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'grid', gap: 2, pt: 2 }}>
            <TextField
              label="Title"
              fullWidth
              value={formData.title}
              onChange={handleFormChange('title')}
              disabled={isSaving}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={formData.description}
              onChange={handleFormChange('description')}
              disabled={isSaving}
            />
            <FormControl fullWidth>
              <InputLabel>Assigned To</InputLabel>
              <Select
                value={formData.assignedTo}
                onChange={handleFormChange('assignedTo')}
                label="Assigned To"
                disabled={isSaving}
              >
                <MenuItem value="user">Me</MenuItem>
                <MenuItem value="space_partner">Space Partner</MenuItem>
                <MenuItem value="lead">Lead</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Due Date"
                value={formData.dueDate ? moment(formData.dueDate.toDate()) : null}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    disabled: isSaving
                  }
                }}
              />
            </LocalizationProvider>
            <FormControl fullWidth>
              <InputLabel>Link To</InputLabel>
              <Select
                value="proposal"
                label="Link To"
                disabled={isSaving}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="lead">Lead</MenuItem>
                <MenuItem value="proposal">Proposal</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Select Proposal</InputLabel>
              <Select
                value={currentProposalName}
                label="Select Proposal"
                  disabled={isSaving}
              >
                <MenuItem value={currentProposalName}>
                  {currentProposalName}
                </MenuItem>
              </Select>
            </FormControl>
            {isEditMode && (
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={formData.status}
                  onChange={handleFormChange('status')}
                  label="Status"
                  disabled={isSaving}
                >
                  <MenuItem value="open">Open</MenuItem>
                  <MenuItem value="in_progress">In Progress</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} disabled={isSaving}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={isSaving}
            startIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            {isEditMode ? 'Save Changes' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const NotesDialog = ({ open, onClose, selectedNotes, email }) => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6">
              Notes - {selectedCenterId}
            </Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          {!selectedNotes && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '200px',
              color: 'text.secondary'
            }}>
              <NotesIcon sx={{ fontSize: 48, mb: 2, opacity: 0.5 }} />
              <Typography variant="body1">No notes available</Typography>
            </Box>
          )}
          {(!selectedNotes || selectedNotes.notes[0].notesBy.length === 0) && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '200px',
              color: 'text.secondary'
            }}>
              <NotesIcon sx={{ fontSize: 48, mb: 2, opacity: 0.5 }} />
              <Typography variant="body1">No notes available</Typography>
            </Box>
          )}
          {selectedNotes && selectedNotes.notes[0].notesBy.map((note, index) => (
            <Box
              key={index}
              sx={{
                p: 2,
                mb: 2,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                <NotesIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {note.text}
                </Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 1,
                pt: 1,
              }}>
                <Typography variant="body2" color="text.secondary">
                  Posted by {note.email}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                {formatDate(note.timestamp)}
                </Typography>
              </Box>
            </Box>
          ))}

        </DialogContent>
      </Dialog>
    );
  };

  const handleCloseNotesDialog = () => {
    setNotesAnchorEl(null);
    setSelectedNotes(null);
    setIsNotesDialogOpen(false);
  };

  const handleCloseQuestionsDialog = () => {
    setQuestionsAnchorEl(null);
    setSelectedQuestions(null);
    setIsQuestionsDialogOpen(false);
  };

  const getOptionWidth = (optionsCount) => {
    switch (optionsCount) {
      case 0:
        return '0%';
      case 1:
        return '80%';
      default:
        return 'auto';
    }
  };

  const renderNotesButton = (centerNotes) => {
    const hasNotes = getAllNotes().length > 0;

    return hasNotes ? (
      <Tooltip
        title={
          <div>
            {getAllNotes().map((note, noteIndex) => (
              <div key={noteIndex}>
                {formatNoteText(note)}
              </div>
            ))}
          </div>
        }
        arrow
        placement="top"
      >
        <IconButton
          size="small"
          color="primary"
          className="notes-icon"
          onClick={(e) => handleOpenNotesDialog(centerNotes, e)}
        >
          <NotesIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton
        size="small"
        disabled
        className="notes-icon-disabled"
      >
        <NotesIcon />
      </IconButton>
    );
  };

  const renderQuestionsButton = (centerFeedback) => {
    const hasQuestions = getAllQuestions().length > 0;

    return hasQuestions ? (
      <Tooltip
        title={
          <div>
            {getAllQuestions().map((feedback, noteIndex) => (
              <div key={noteIndex}>
                {formatQuestionText(feedback)}
              </div>
            ))}
          </div>
        }
        arrow
        placement="top"
      >
        <IconButton
          size="small"
          color="secondary"
          className="questions-icon"
          onClick={(e) => handleOpenQuestionsDialog(centerFeedback, e)}
        >
          <QuestionMarkIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton
        size="small"
        disabled
        className="questions-icon-disabled"
      >
        <QuestionMarkIcon />
      </IconButton>
    );
  };

  const renderCenterActions = (center) => {
    const centerNotes = getNotesForCenter(center.centerName);
    const centerQuestions = getQuestionsForCenter(center.centerName);

    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Tooltip title={`View Notes (${centerNotes.length})`}>
          <IconButton
            size="small"
            onClick={(e) => handleOpenNotesDialog(center.centerName, e)}
            sx={{ 
              color: centerNotes.length > 0 ? 'primary.main' : 'inherit',
              bgcolor: centerNotes.length > 0 ? 'primary.lighter' : 'transparent',
              '&:hover': {
                bgcolor: centerNotes.length > 0 ? 'primary.light' : undefined
              }
            }}
          >
            <NotesIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={`View Questions (${centerQuestions.length})`}>
          <IconButton
            size="small"
            onClick={(e) => handleOpenQuestionsDialog(center.centerName, e)}
            sx={{ 
              color: centerQuestions.length > 0 ? 'warning.main' : 'inherit',
              bgcolor: centerQuestions.length > 0 ? 'warning.lighter' : 'transparent',
              '&:hover': {
                bgcolor: centerQuestions.length > 0 ? 'warning.light' : undefined
              }
            }}
          >
            <QuestionMarkIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const renderTableHeaders = (options) => (
    <tr>
      <th className="sticky-column" style={{ width: "150px" }}>Space Details</th>
      <th colSpan={options?.length || 1} style={{ padding: 0 }}>
        {isEditable ? (
          <StrictModeDroppable droppableId="centers" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  display: 'flex',
                  width: '100%',
                  minHeight: '50px',
                  background: snapshot.isDraggingOver ? 'rgba(63, 81, 181, 0.05)' : 'transparent'
                }}
              >
                {options?.map((option, index) => (
                  <Draggable
                    key={`${option.centerName}-${index}`}
                    draggableId={`${option.centerName}-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          flex: `1 0 ${100 / (options?.length || 1)}%`,
                          padding: '12px',
                          userSelect: 'none',
                          backgroundColor: snapshot.isDragging ? 'rgba(63, 81, 181, 0.1)' : 'transparent',
                          ...provided.draggableProps.style
                        }}
                      >
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px',
                          cursor: 'move'
                        }}>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCenterClick(option.centerName);
                            }}
                            className="fw-bold"
                            style={{
                              color: 'inherit',
                              textDecoration: 'underline',
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            {option.centerName || "N/A"}
                          </span>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditCenter(proposal.proposedOptions.findIndex(item => item.centerName === option.centerName));
                            }}
                            size="small"
                            sx={{
                              padding: '2px',
                              color: '#000',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)'
                              }
                            }}
                          >
                            <EditIcon sx={{ fontSize: '0.9rem' }} />
                          </IconButton>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        ) : (
          <div style={{ display: 'flex' }} width={getOptionWidth(options?.length)}>
            {options?.map((option, index) => (
              <div
                key={index}
                style={{
                  flex: `1 0 ${100 / (options?.length || 1)}%`,
                  padding: '12px',
                  textAlign: 'center'
                }}
              >
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCenterClick(option.centerName);
                  }}
                  className="fw-bold"
                  style={{
                    color: 'inherit',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {option.centerName || "N/A"}
                </span>
              </div>
            ))}
          </div>
        )}
      </th>
    </tr>
  );

  const renderTableBody = (options, proposal) => {
    const optionWidth = getOptionWidth(options?.length);
    return (
      <tbody style={{
        width: optionWidth,
        maxWidth: optionWidth
      }}>
        <tr>
          <td className="fw-bold sticky-column">Price per Seat</td>
          {options?.map((option, index) => (
            <td className="table-values" key={index}>
              {formatPrice(option.pricePerSeat)}
            </td>
          ))}
        </tr>

        <tr>
          <td className="fw-bold sticky-column">Billable Seats</td>
          {options?.map((option, index) => (
            <td className="table-values" key={index}>
              {option.billableSeats}
            </td>
          ))}
        </tr>
        <tr>
          <td className="fw-bold sticky-column text-wrap">Total Rent per Month</td>
          {options?.map((option, index) => (
            <td className="table-values" key={index}>
              {formatPrice(option.totalRentPerMonth)}
            </td>
          ))}
        </tr>

        <tr>
          <td className="fw-bold sticky-column">Location</td>
          {options?.map((option, index) => (
            <td className="table-values" key={index}>
              {option.locality || "N/A"}
            </td>
          ))}
        </tr>
        <tr>
          <td className="fw-bold sticky-column">Security Deposit</td>
          {options?.map((option, index) => (
            <td className="table-values" key={index}>
              {formatPrice(option.securityDeposit)}
            </td>
          ))}
        </tr>
        <tr>
          <td className="fw-bold sticky-column">Notice Period</td>
          {options?.map((option, index) => (
            <td key={index}>
              {option.noticePeriod || "N/A"} Month
            </td>
          ))}
        </tr>
        <tr>
          <td className="fw-bold sticky-column">Lockin Period</td>
          {options?.map((option, index) => (
            <td key={index}>
              {option.lockinPeriod || "N/A"} Month
            </td>
          ))}
        </tr>
        <tr>
          <td className="fw-bold sticky-column">Annual Escalation </td>
          {options?.map((option, index) => (
            <td className="table-values" key={index}>
              {option.annualEscalation ? `${option.annualEscalation}%` : "N/A"}
            </td>
          ))}
        </tr>
        <tr>
          <td className="fw-bold sticky-column">Parking Charges</td>
          {options?.map((option, index) => (
            <td key={index} className="table-values text-wrap">
              Car-{formatPrice(option.carParkingCost)} & Bike-
              {formatPrice(option.bikeParkingCost)}
            </td>
          ))}
        </tr>
        <tr>
          <td className="fw-bold sticky-column">Available From</td>
          {options?.map((option, index) => (
            <td className="table-values" key={index}>
              {option.availableFrom || "N/A"}
            </td>
          ))}
        </tr>

        <tr>
          <td className="fw-bold sticky-column">Notes & Questions</td>
          {options?.map((option, index) => (
            <td className="table-values" key={index}>
              {renderCenterActions(option)}
            </td>
          ))}
        </tr>
      </tbody>
    );
  };

  // Filter options based on selected tab
  const recommendedOptions = proposal?.proposedOptions?.filter(option => option.isRecommended) || [];
  const otherOptions = proposal?.proposedOptions?.filter(option => !option.isRecommended) || [];
  const currentOptions = selectedTab === 0 ? recommendedOptions : otherOptions;

  return (
    <div className="container mt-5" id="RecommendedSpaces">
      {isEditable && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
          <Button
            variant="contained"
            onClick={handleAddNewOption}
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              borderColor: (theme) => theme.palette.primary.main,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.primary.dark,
                borderColor: (theme) => theme.palette.primary.dark,
              }
            }}
          >
            Add New Option
          </Button>
        </div>
      )}

      <EditCenterDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        center={editingCenter}
        onSave={handleSaveCenter}
        onDelete={handleDeleteCenter}
      />

      <SearchCenterDialog
        open={searchDialogOpen}
        onClose={handleCloseSearchDialog}
        onAddToProposal={onAddToProposal}
      />

      <NotesDialog 
        open={Boolean(notesAnchorEl)} 
        onClose={handleCloseNotesDialog} 
        selectedNotes={selectedNotes} 
        email={user?user.email:auth.currentUser.email} 
      />

      <Dialog
        open={Boolean(questionsAnchorEl)}
        onClose={handleCloseQuestionsDialog}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            minHeight: '70vh',
            maxHeight: '85vh'
          }
        }}
      >
        <Box sx={{ p: 2, overflow: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">
              Questions - {selectedCenterId}
            </Typography>
            <IconButton size="small" onClick={handleCloseQuestionsDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
          {renderQuestionsList()}
        </Box>
      </Dialog>

      {renderActionItemDialog()}

      <Dialog
        open={answerDialogOpen}
        onClose={() => {
          setAnswerDialogOpen(false);
          setAnswer('');
          setAnswerError('');
          setSelectedQuestion(null);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Answer Question</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Question: {selectedQuestion?.feedbackBy[0]?.text}
            </Typography>
            <TextField
              autoFocus
              multiline
              rows={4}
              fullWidth
              label="Your Answer"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              error={!!answerError}
              helperText={answerError}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setAnswerDialogOpen(false);
            setAnswer('');
            setAnswerError('');
            setSelectedQuestion(null);
          }}>
            Cancel
          </Button>
          <Button onClick={handleAnswerQuestion} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <div className="table-responsive mb-5">
        {currentOptions && currentOptions.length > 0 ? (
          isEditable ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <table className="scrollable-table table-bordered align-middle text-center">
                <thead className="header text-white">
                  {renderTableHeaders(currentOptions)}
                </thead>
                {renderTableBody(currentOptions, proposal)}
              </table>
            </DragDropContext>
          ) : (
            <table className="scrollable-table table-bordered align-middle text-center">
              <thead className="header text-white">
                {renderTableHeaders(currentOptions)}
              </thead>
              {renderTableBody(currentOptions, proposal)}
            </table>
          )
        ) : null}
      </div>

      <hr />
    </div>
  );
};

export default Recommendedspaces;
