import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import DescriptionIcon from "@mui/icons-material/Description";
import PeopleIcon from "@mui/icons-material/People";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const stats = [
    {
      title: "Total Proposals",
      value: "125",
      change: "+12.5%",
      trend: "up",
      icon: <DescriptionIcon sx={{ fontSize: 40 }} />,
      color: "#004D40",
    },
    {
      title: "Active Clients",
      value: "45",
      change: "+8.2%",
      trend: "up",
      icon: <PeopleIcon sx={{ fontSize: 40 }} />,
      color: "#1976D2",
    },
    {
      title: "Revenue",
      value: "$52.5K",
      change: "-2.4%",
      trend: "down",
      icon: <AttachMoneyIcon sx={{ fontSize: 40 }} />,
      color: "#D32F2F",
    },
    {
      title: "Success Rate",
      value: "89%",
      change: "+5.6%",
      trend: "up",
      icon: <CheckCircleIcon sx={{ fontSize: 40 }} />,
      color: "#388E3C",
    },
  ];

  return (
    <Box sx={{ p: { xs: 2, md: 3 }, bgcolor: "#f5f5f5", minHeight: "100vh" }}>
      <Typography
        variant="h5"
        sx={{
          mb: 3,
          color: "#1e1e1e",
          fontWeight: 500,
        }}
      >
        Dashboard
      </Typography>

      <Grid container spacing={3}>
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
                boxShadow: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    p: 1,
                    borderRadius: "8px",
                    bgcolor: `${stat.color}10`,
                  }}
                >
                  {React.cloneElement(stat.icon, { sx: { color: stat.color } })}
                </Box>
                <IconButton size="small">
                  <MoreVertIcon sx={{ color: "#757575" }} />
                </IconButton>
              </Box>

              <Typography
                variant="h4"
                sx={{
                  mb: 1,
                  fontWeight: 600,
                  color: "#1e1e1e",
                }}
              >
                {stat.value}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#757575",
                    fontWeight: 500,
                  }}
                >
                  {stat.title}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    color: stat.trend === "up" ? "#388E3C" : "#D32F2F",
                  }}
                >
                  {stat.trend === "up" ? (
                    <TrendingUpIcon sx={{ fontSize: 16 }} />
                  ) : (
                    <TrendingDownIcon sx={{ fontSize: 16 }} />
                  )}
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {stat.change}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Dashboard;