import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  Chip
} from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { addActionItem, updateActionItem } from '../../Services/FirebaseUtils';
import { Timestamp } from 'firebase/firestore';

const ActionItemForm = ({ open, onClose, item = null, onSuccess }) => {
  const userData = useSelector((state) => state.user.data);
  const { items: leads, loading: leadsLoading } = useSelector((state) => state.leads);
  const { items: proposals, loading: proposalsLoading } = useSelector((state) => state.proposals);

  const getInitialFormData = (actionItem) => {
    const initialData = {
      title: actionItem?.title || '',
      description: actionItem?.description || '',
      assignedTo: actionItem?.assignedTo || '',
      leadId: actionItem?.leadId || '',
      leadName: actionItem?.leadName || '',
      proposalId: actionItem?.proposalId || '',
      proposalName: actionItem?.proposalName || '',
      status: actionItem?.status || 'open'
    };

    // Handle different date formats
    if (actionItem?.dueDate) {
      if (actionItem.dueDate.toDate) {
        // Firestore Timestamp
        initialData.dueDate = moment(actionItem.dueDate.toDate());
      } else if (actionItem.dueDate._seconds) {
        // Firestore Timestamp in different format
        initialData.dueDate = moment.unix(actionItem.dueDate._seconds);
      } else if (moment.isMoment(actionItem.dueDate)) {
        // Already a moment object
        initialData.dueDate = actionItem.dueDate;
      } else {
        // Try to parse as regular date
        initialData.dueDate = moment(actionItem.dueDate);
      }
    } else {
      // Default to current date/time
      initialData.dueDate = moment();
    }

    return initialData;
  };

  const [formData, setFormData] = useState(getInitialFormData(item));
  const [isSaving, setIsSaving] = useState(false);
  const [linkType, setLinkType] = useState(
    item?.leadId ? 'lead' : item?.proposalId ? 'proposal' : ''
  );

  // Update form data when item changes
  useEffect(() => {
    if (open) {
      setFormData(getInitialFormData(item));
      setLinkType(item?.leadId ? 'lead' : item?.proposalId ? 'proposal' : '');
    }
  }, [item, open]);

  const handleDateChange = (date) => {
    setFormData(prev => ({
      ...prev,
      dueDate: date
    }));
  };

  const handleLinkTypeChange = (event) => {
    const newLinkType = event.target.value;
    setLinkType(newLinkType);
    
    // Reset related fields when link type changes
    setFormData(prev => ({
      ...prev,
      leadId: '',
      leadName: '',
      proposalId: '',
      proposalName: ''
    }));
  };

  const handleLinkedItemChange = (event) => {
    const selectedId = event.target.value;
    
    if (linkType === 'lead') {
      const selectedLead = leads.find(lead => lead.id === selectedId);
      if (selectedLead) {
        setFormData(prev => ({
          ...prev,
          leadId: selectedId,
          leadName: selectedLead.name || selectedLead.companyName || 'Unnamed Lead',
          proposalId: '',
          proposalName: ''
        }));
      }
    } else if (linkType === 'proposal') {
      const selectedProposal = proposals.find(proposal => proposal.id === selectedId);
      if (selectedProposal) {
        setFormData(prev => ({
          ...prev,
          proposalId: selectedId,
          proposalName: selectedProposal.clientName || 'Unnamed Client',
          leadId: '',
          leadName: ''
        }));
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!userData?.email) {
      onClose();
      return;
    }

    if (!formData.dueDate || !moment(formData.dueDate).isValid()) {
      return;
    }

    setIsSaving(true);
    try {
      const dueDateObj = formData.dueDate.toDate();

      const data = {
        title: formData.title,
        description: formData.description,
        dueDate: Timestamp.fromDate(dueDateObj),
        assignedTo: formData.assignedTo,
        status: formData.status,
        updatedAt: Timestamp.now(),
        owner: userData.email,
        leadId: null,
        leadName: null,
        proposalId: null,
        proposalName: null
      };

      if (linkType === 'lead' && formData.leadId) {
        data.leadId = formData.leadId;
        data.leadName = formData.leadName;
      } else if (linkType === 'proposal' && formData.proposalId) {
        data.proposalId = formData.proposalId;
        data.proposalName = formData.proposalName;
      }

      if (!item) {
        data.createdAt = Timestamp.now();
        await addActionItem(data);
      } else {
        delete data.owner;
        await updateActionItem(item.id, data);
      }

      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Error saving action item:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{item ? 'Edit Action Item' : 'Add Action Item'}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box sx={{ display: 'grid', gap: 2, pt: 2 }}>
            <TextField
              label="Title"
              fullWidth
              required
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              disabled={isSaving}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              disabled={isSaving}
            />
            <FormControl fullWidth>
              <InputLabel>Assigned To</InputLabel>
              <Select
                value={formData.assignedTo}
                onChange={(e) => setFormData({ ...formData, assignedTo: e.target.value })}
                label="Assigned To"
                disabled={isSaving}
              >
                <MenuItem value="user">Me</MenuItem>
                <MenuItem value="space_partner">Space Partner</MenuItem>
                <MenuItem value="lead">Lead</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Due Date"
                value={formData.dueDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    fullWidth
                    required
                    error={!formData.dueDate || !moment(formData.dueDate).isValid()}
                    helperText={!formData.dueDate ? "Due date is required" : !moment(formData.dueDate).isValid() ? "Invalid date" : ""}
                    disabled={isSaving}
                  />
                )}
              />
            </LocalizationProvider>
            <FormControl fullWidth>
              <InputLabel>Link To</InputLabel>
              <Select
                value={linkType}
                onChange={handleLinkTypeChange}
                label="Link To"
                disabled={isSaving}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="lead">Lead</MenuItem>
                <MenuItem value="proposal">Proposal</MenuItem>
              </Select>
            </FormControl>
            {linkType === 'lead' && (
              <FormControl fullWidth>
                <InputLabel>Select Lead</InputLabel>
                <Select
                  value={formData.leadId}
                  onChange={handleLinkedItemChange}
                  label="Select Lead"
                  disabled={isSaving || leadsLoading || !leads?.length}
                >
                  {leads?.map((lead) => (
                    <MenuItem key={lead.id} value={lead.id}>
                      {lead.name || lead.companyName || 'Unnamed Lead'}
                    </MenuItem>
                  ))}
                </Select>
                {leadsLoading && (
                  <FormHelperText>Loading leads...</FormHelperText>
                )}
                {!leadsLoading && !leads?.length && (
                  <FormHelperText>No leads available</FormHelperText>
                )}
              </FormControl>
            )}
            {linkType === 'proposal' && (
              <FormControl fullWidth>
                <InputLabel>Select Proposal</InputLabel>
                <Select
                  value={formData.proposalId}
                  onChange={handleLinkedItemChange}
                  label="Select Proposal"
                  disabled={isSaving || proposalsLoading || !proposals?.length}
                >
                  {proposals?.map((proposal) => (
                    <MenuItem key={proposal.id} value={proposal.id}>
                      {proposal.clientName || 'Unnamed Client'}
                    </MenuItem>
                  ))}
                </Select>
                {proposalsLoading && (
                  <FormHelperText>Loading proposals...</FormHelperText>
                )}
                {!proposalsLoading && !proposals?.length && (
                  <FormHelperText>No proposals available</FormHelperText>
                )}
              </FormControl>
            )}
            {item && (
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={formData.status}
                  onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                  label="Status"
                  disabled={isSaving}
                >
                  <MenuItem value="open">Open</MenuItem>
                  <MenuItem value="in_progress">In Progress</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isSaving}>Cancel</Button>
          <Button 
            type="submit" 
            variant="contained"
            disabled={isSaving || !formData.title || !formData.dueDate || !moment(formData.dueDate).isValid()}
            startIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            {item ? 'Save Changes' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ActionItemForm;
