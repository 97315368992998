import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { Box, Tabs, Tab } from '@mui/material';
import "../../../Styles/Styles.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/config';

const containerStyle = {
    width: '100%',
    height: '50vh',
    border: "2px solid #ddd"
};

// Custom map styles
const mapStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ADD8E6"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
];

const mapOptions = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

const formatPrice = (price) =>
    price !== undefined && price !== null
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(price)
      : "N/A";

const Recommandedlocation = ({ 
    proposal,
    selectedTab,
    onTabChange
}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBFzQyh0__KlIMKsmGqMIsnsolOeXTYoUs',
    });

    const [map, setMap] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [zoom, setZoom] = useState(13);

    // Filter options based on selected tab
    const recommendedOptions = proposal?.proposedOptions?.filter(option => option.isRecommended) || [];
    const otherOptions = proposal?.proposedOptions?.filter(option => !option.isRecommended) || [];
    const currentOptions = selectedTab === 0 ? recommendedOptions : otherOptions;

    // Get locations for the current tab
    const locations = currentOptions.map((option) => {
        const loc = option.location;
        return {
            lat: loc.lat,
            lng: loc.lng,
            centerName: option.centerName,
            locality: option.locality,
            billableSeats: option.billableSeats,
            pricePerSeat: formatPrice(option.pricePerSeat),
            availableFrom: option.availableFrom
        };
    });

    const [center, setCenter] = useState(() => {
        if (locations.length > 0) {
            return { lat: Number(locations[0].lat), lng: Number(locations[0].lng) };
        }
        return { lat: 13.0827, lng: 80.2707 };
    });

    useEffect(() => {
        if (locations.length > 0) {
            const newCenter = { 
                lat: Number(locations[0].lat), 
                lng: Number(locations[0].lng) 
            };
            
            // Only update if the center has actually changed
            if (center.lat !== newCenter.lat || center.lng !== newCenter.lng) {
                setCenter(newCenter);
                if (map) {
                    map.panTo(newCenter);
                }
            }
        }
    }, [selectedTab, locations, map, center.lat, center.lng]);

    const handleTabChange = async (event, newValue) => {
        onTabChange(newValue);
        setSelectedMarker(null);

        // Update Firestore
        try {
            if (proposal?.id) {
                const proposalRef = doc(db, 'proposals', proposal.id);
                await updateDoc(proposalRef, {
                    proposedOptions: proposal.proposedOptions
                });
            }
        } catch (error) {
            console.error('Error updating Firestore:', error);
        }
    };

    const onLoad = useCallback((map) => {
        if (locations.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            locations.forEach((location) => {
                bounds.extend(new window.google.maps.LatLng(Number(location.lat), Number(location.lng)));
            });
            map.fitBounds(bounds);
        }
        setMap(map);
    }, [locations]);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const handleTouchStart = (event, rowId) => {
        if (map && locations[rowId]) {
            map.panTo({ 
                lat: Number(locations[rowId].lat), 
                lng: Number(locations[rowId].lng) 
            });
            setSelectedMarker(locations[rowId]);
        }
    };

    return isLoaded ? (
        <div className="container mt-4">
            <h1 className="display-4 text-start mb-4">
            Proposed Options
            </h1>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab sx={{ fontWeight: 'bold' , fontSize: '1rem' }} label={`Recommended Options (${recommendedOptions.length})`} />
                    <Tab sx={{ fontWeight: 'bold' , fontSize: '1rem'}} label={`Other Options (${otherOptions.length})`} />
                </Tabs>
            </Box>

            <div className="row">
                <div className="col-lg-6 col-md-12 mb-3">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={mapOptions}
                    >
                        {locations.map((location, index) => (
                            <MarkerF
                                key={index}
                                position={{ lat: Number(location.lat), lng: Number(location.lng) }}
                                onClick={() => setSelectedMarker(location)}
                                options={{ clickable: true }}
                                label={`${index + 1}`}
                            />
                        ))}
                        {selectedMarker && (
                            <InfoWindowF
                                key={`info-window-${selectedMarker.lat}-${selectedMarker.lng}`}
                                position={{ lat: Number(selectedMarker.lat), lng: Number(selectedMarker.lng) }}
                                onCloseClick={() => setSelectedMarker(null)}
                            >
                                <div>
                                    <h5>{selectedMarker.centerName}</h5>
                                    <p>{selectedMarker.locality}</p>
                                    <p>Price Per Seat : {selectedMarker.pricePerSeat}</p>
                                    <p>Billable Seats : {selectedMarker.billableSeats}</p>
                                    <p>Available From : {selectedMarker.availableFrom}</p>
                                </div>
                            </InfoWindowF>
                        )}
                    </GoogleMap>
                </div>

                <div className="col-lg-6 col-md-12">
                    <div className="table-responsive">
                        <div className="table-container">
                            <table className="location-table table-bordered" style={{ maxHeight: "600px", overflowY: "scroll" }}>
                                <tbody>
                                    {currentOptions.map((item, index) => (
                                        <tr 
                                            key={index} 
                                            onTouchStart={(e) => handleTouchStart(e, index)}
                                            onClick={(e) => handleTouchStart(e, index)}
                                        >
                                            <td>{index + 1}</td>
                                            <td className='location-column p-3'>
                                                {item.centerName || "N/A"}
                                            </td>
                                            <td className='p-3'>{item.location.address}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="text-center">Loading...</div>
    );
};

export default Recommandedlocation;
