import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Grid,
    IconButton
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import dayjs from 'dayjs';
import { updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../Component/Public/RecommendedSpaces/StrictModeDroppable';
import VistMap from '../../Components/Private/VistMap';

const VisitSchedule = ({ proposal, proposalId, shortlistedCenters, isEditable = false }) => {
    const [scheduleData, setScheduleData] = useState([]);
    const [editingId, setEditingId] = useState(null);

    useEffect(() => {
        if (proposal?.proposedOptions && shortlistedCenters) {
            // Filter only shortlisted centers and transform into schedule data
            const formattedSchedules = proposal.proposedOptions
                .filter(option => shortlistedCenters[option.centerName]?.length > 0)
                .map((option, index) => {
                    // Find matching visit schedule if it exists
                    const visitSchedule = proposal.visitSchedule?.find(
                        schedule => schedule.inventoryName === option.centerName
                    );

                    return {
                        id: option.centerName,
                        sNo: index + 1,
                        option: option.centerName || 'N/A',
                        address: option.location?.address || 'N/A',
                        date: visitSchedule?.date ? dayjs(visitSchedule.date) : null,
                        time: visitSchedule?.time ? dayjs(`2024-01-01T${visitSchedule.time}`) : null
                    };
                });
            setScheduleData(formattedSchedules);
        }
    }, [proposal, shortlistedCenters]);

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(scheduleData);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // Update the sNo for all items
        const updatedItems = items.map((item, index) => ({
            ...item,
            sNo: index + 1
        }));

        setScheduleData(updatedItems);

        // Create the new visit schedule array
        const visitSchedule = updatedItems.map(item => ({
            inventoryName: item.option,
            date: item.date?.format('YYYY-MM-DD') || null,
            time: item.time?.format('HH:mm') || null
        }));

        // Update Firestore with the new schedule
        try {
            await updateDoc(doc(db, 'proposals', proposalId), {
                    proposedOptions: [visitSchedule]
            });
        } catch (error) {
            console.error('Error updating order:', error);
        }
    };

    const handleSaveDateTime = async (row) => {
        try {
            console.log('Saving row:', row);
            console.log('Current schedule data:', scheduleData);

            // First update the local state
            const updatedScheduleData = scheduleData.map(item => 
                item.id === row.id ? { ...item, date: row.date, time: row.time } : item
            );
            setScheduleData(updatedScheduleData);

            // Create the Firestore update data
            const visitSchedule = updatedScheduleData.map(item => ({
                inventoryName: item.option,
                date: item.date?.isValid() ? item.date.format('YYYY-MM-DD') : null,
                time: item.time?.isValid() ? item.time.format('HH:mm') : null
            }));

            console.log('Updating Firestore with:', visitSchedule);

            // Get the current proposal data
            const proposalRef = doc(db, 'proposals', proposalId);
            const proposalDoc = await getDoc(proposalRef);
            
            if (!proposalDoc.exists()) {
                throw new Error('Proposal not found');
            }

            // Merge the existing data with the updated visit schedule
            const updatedData = {
                ...proposalDoc.data(),
                visitSchedule: visitSchedule
            };

            // Update Firestore
            await updateDoc(proposalRef, updatedData);
            console.log('Firestore update successful');

            setEditingId(null);
        } catch (error) {
            console.error('Error saving date/time:', error);
            // Show error to user
            alert('Failed to save changes. Please try again.');
        }
    };

    const handleDateChange = (rowId, newDate) => {
        console.log('Date change:', rowId, newDate);
        setScheduleData(prev => prev.map(row => {
            if (row.id === rowId) {
                const updated = {
                    ...row,
                    date: newDate
                };
                console.log('Updated row:', updated);
                return updated;
            }
            return row;
        }));
    };

    const handleTimeChange = (rowId, newTime) => {
        console.log('Time change:', rowId, newTime);
        setScheduleData(prev => prev.map(row => {
            if (row.id === rowId) {
                const updated = {
                    ...row,
                    time: newTime
                };
                console.log('Updated row:', updated);
                return updated;
            }
            return row;
        }));
    };

    if (!proposal || !scheduleData.length) {
        return null;
    }

    return (
        <Box id="visit-schedule" p={3} sx={{ mx: 2 }}>
            <h1 className="display-4 text-start mx-4 mb-4">Visit Schedule</h1>
            <Grid container spacing={2} mx={2.5}>
                <Grid item xs={11} md={7}>
                    {isEditable ? (
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <TableContainer className="location-table">
                                <Table className="table-bordered">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='location-column' width="50px"></TableCell>
                                            <TableCell className='location-column table-bordered'>S.No</TableCell>
                                            <TableCell className='location-column'>Option</TableCell>
                                            <TableCell className='location-column'>Address</TableCell>
                                            <TableCell className='location-column'>Date</TableCell>
                                            <TableCell className='location-column'>Time</TableCell>
                                            <TableCell className='location-column'>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <StrictModeDroppable droppableId="scheduleTable">
                                        {(provided) => (
                                            <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                                                {scheduleData.map((row, index) => (
                                                    <Draggable
                                                        key={row.id.toString()}
                                                        draggableId={row.id.toString()}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <TableRow
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                sx={{
                                                                    bgcolor: snapshot.isDragging ? 'action.hover' : 'inherit'
                                                                }}
                                                            >
                                                                <TableCell {...provided.dragHandleProps}>
                                                                    <DragIndicatorIcon />
                                                                </TableCell>
                                                                <TableCell>{row.sNo}</TableCell>
                                                                <TableCell>{row.option}</TableCell>
                                                                <TableCell>{row.address}</TableCell>
                                                                <TableCell>
                                                                    {editingId === row.id ? (
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DatePicker
                                                                                value={row.date}
                                                                                onChange={(newDate) => handleDateChange(row.id, newDate)}
                                                                                format="DD/MM/YYYY"
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        size: "small",
                                                                                        sx: { width: '120px' }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </LocalizationProvider>
                                                                    ) : (
                                                                        row.date?.format('DD/MM/YYYY') || 'Not set'
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {editingId === row.id ? (
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <TimePicker
                                                                                value={row.time}
                                                                                onChange={(newTime) => handleTimeChange(row.id, newTime)}
                                                                                format="HH:mm"
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        size: "small",
                                                                                        sx: { width: '120px' }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </LocalizationProvider>
                                                                    ) : (
                                                                        row.time?.format('HH:mm') || 'Not set'
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {editingId === row.id ? (
                                                                        <IconButton onClick={() => handleSaveDateTime(row)}>
                                                                            <SaveIcon />
                                                                        </IconButton>
                                                                    ) : (
                                                                        <IconButton onClick={() => setEditingId(row.id)}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </TableBody>
                                        )}
                                    </StrictModeDroppable>
                                </Table>
                            </TableContainer>
                        </DragDropContext>
                    ) : (
                        <TableContainer className="location-table">
                            <Table className="table-bordered">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='location-column table-bordered'>S.No</TableCell>
                                        <TableCell className='location-column'>Option</TableCell>
                                        <TableCell className='location-column'>Address</TableCell>
                                        <TableCell className='location-column'>Date</TableCell>
                                        <TableCell className='location-column'>Time</TableCell>
                                        <TableCell className='location-column'>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {scheduleData.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.sNo}</TableCell>
                                            <TableCell>{row.option}</TableCell>
                                            <TableCell>{row.address}</TableCell>
                                            <TableCell>
                                                {editingId === row.id ? (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={row.date}
                                                            onChange={(newDate) => handleDateChange(row.id, newDate)}
                                                            format="DD/MM/YYYY"
                                                            slotProps={{
                                                                textField: {
                                                                    size: "small",
                                                                    sx: { width: '120px' }
                                                                }
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                ) : (
                                                    row.date?.format('DD/MM/YYYY') || 'Not set'
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {editingId === row.id ? (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <TimePicker
                                                            value={row.time}
                                                            onChange={(newTime) => handleTimeChange(row.id, newTime)}
                                                            format="HH:mm"
                                                            slotProps={{
                                                                textField: {
                                                                    size: "small",
                                                                    sx: { width: '120px' }
                                                                }
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                ) : (
                                                    row.time?.format('HH:mm') || 'Not set'
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {editingId === row.id ? (
                                                    <IconButton onClick={() => handleSaveDateTime(row)}>
                                                        <SaveIcon />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton onClick={() => setEditingId(row.id)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>

                <Grid item xs={6} md={5}>
                    <Paper sx={{ height: '350px', p: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Map View
                        </Typography>
                        <VistMap proposal={proposal} scheduleData={scheduleData} />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default VisitSchedule;
