import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/config';
import InventoryForm from '../../Components/Private/InventoryForm';
import CenterForm from '../../Components/Private/CenterForm';
import { v4 as uuidv4 } from 'uuid';
import { fetchInventories, updateInventory, fetchCenters } from '../../Services/FirebaseUtils';
import { doc, setDoc, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import PropTypes from 'prop-types';

const cities = ['Bangalore', 'Chennai', 'Hyderabad', 'Mumbai', 'Delhi', 'Pune'];

const ImportInventoryModal = ({ 
  open, 
  onClose, 
  onImport, 
  onEditItem, 
  operatorName, 
  centerInventoryData = {}, 
  operatorId, 
  theme,
  onDataRefresh 
}) => {
  // Validate and normalize centerInventoryData
  const centers = Array.isArray(centerInventoryData?.centers) 
    ? centerInventoryData.centers 
    : [];
  const existingInventories = Array.isArray(centerInventoryData?.existingInventories) 
    ? centerInventoryData.existingInventories 
    : [];

  console.log('ImportInventoryModal props:', { 
    onEditItem: typeof onEditItem, 
    operatorName, 
    centerInventoryData,
    centersCount: centerInventoryData?.centers?.length
  });

  const [selectedCity, setSelectedCity] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [importData, setImportData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [extractedData, setExtractedData] = useState([]);
  const [centerMappings, setCenterMappings] = useState({});
  const [inventoryMappings, setInventoryMappings] = useState({});
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInventoryFormOpen, setIsInventoryFormOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [showInventoryForm, setShowInventoryForm] = useState(false);
  const [showCenterForm, setShowCenterForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newCenterData, setNewCenterData] = useState(null);
  const [error, setError] = useState(null);
  const [extractedInventories, setExtractedInventories] = useState([]);
  const transformComponentRef = useRef(null);
  const [center, setCenter] = useState([]);
  console.log(editingItem, 'editingItem');

  const formatDate = (dateStr) => {
    if (!dateStr) return new Date().toISOString().split('T')[0];
    
    // If it's already in YYYY-MM-DD format, return as is
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
      return dateStr;
    }

    // Handle DD/MM/YYYY format
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
      const [day, month, year] = dateStr.split('/');
      return `${year}-${month}-${day}`;
    }
    
    try {
      const date = new Date(dateStr);
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', dateStr);
        return new Date().toISOString().split('T')[0];
      }
      return date.toISOString().split('T')[0];
    } catch (e) {
      console.error('Error parsing date:', dateStr, e);
      return new Date().toISOString().split('T')[0];
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please select an image file');
        return;
      }
      
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setPreviewImage(e.target.result);
        setSelectedFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImport = async () => {
    if (!selectedFile || !selectedCity) {
      setError('Please select all required fields');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      await processImageWithGemini(previewImage);
    } catch (error) {
      console.error('Error during import:', error);
      setError(error.message || 'An error occurred during import');
    } finally {
      setIsLoading(false);
    }
  };

  const processImageWithGemini = async (imageData) => {
    try {
      setIsLoading(true);
      setError(null);

      const importFunction = httpsCallable(functions, 'importInventoryFromImage');
      console.log('Calling import function with:', {
        operatorName,
        cityName: selectedCity,
        imageDataLength: imageData.length
      });

      const result = await importFunction({
        operatorName,
        cityName: selectedCity,
        imageData: imageData
      });

      console.log('Function result:', result);

      if (result.data.success) {
        setImportData(result.data.data);
        setEditedData(result.data.data);
        setExtractedData(result.data.data);
        setError(null);
      } else {
        setError('Failed to process image. Please try again.');
        console.error('Error processing image:', result.data.error);
      }
    } catch (error) {
      console.error('Error calling import function:', error);
      setError(error.message || 'An error occurred while processing the image.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (item) => {
    console.log('Editing item:', item);
    const matchingInventory = existingInventories.find(inv => 
      inv.inventoryName?.toLowerCase?.().trim() === item.inventoryName?.toLowerCase?.().trim() &&
      String(inv.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim()
    );
    setEditingItem(matchingInventory);
    setIsInventoryFormOpen(true);
  };

  const handleInventoryFormClose = () => {
    setIsInventoryFormOpen(false);
    setEditingItem(null);
  };

  const handleInventoryFormSubmit = async (formData) => {
    try {
      console.log('Submitting inventory form data:', formData);
      
      // Ensure we have the original item's ID
      const inventoryId = editingItem?.id || editingItem?.inventoryId || formData?.inventoryId;
      console.log('Using inventoryId:', inventoryId, 'from editingItem:', editingItem);

      if (!inventoryId) {
        // Generate a new ID if none exists
        const newId = uuidv4();
        console.log('Generated new inventoryId:', newId);
        
        // Keep existing data and update with form fields
        const inventoryData = removeUndefinedFields({
          ...editingItem,
          ...formData,
          inventoryId: newId,
          id: newId,
          availabilityDate: formatDate(formData.availabilityDate),
          operatorName,
          active: true,
          updatedAt: new Date().toISOString()
        });

        console.log('Creating new inventory with ID:', newId, 'Data:', inventoryData);
        console.log('Updated Edited Data:', editedData.map(item => item === editingItem ? { ...inventoryData, extractedData: editingItem } : item));
        console.log('Editing Item:', editingItem);
        console.log('Form Data:', formData);

        const updatedEditedData = editedData.map(item => 
          item === editingItem ? { ...inventoryData, extractedData: editingItem } : item
        );
        setEditedData(updatedEditedData);

        console.log('Creating new inventory with ID:', newId, 'Data:', inventoryData);
        await updateInventory(newId, inventoryData);
      } else {
        // Update existing inventory
        const inventoryData = removeUndefinedFields({
          ...editingItem,
          ...formData,
          inventoryId,
          id: inventoryId,
          availabilityDate: formatDate(formData.availabilityDate),
          operatorName,
          active: true,
          updatedAt: new Date().toISOString()
        });

        console.log('Updating existing inventory with ID:', inventoryId, 'Data:', inventoryData);
        console.log('Updated Edited Data:', editedData.map(item => item === editingItem ? { ...inventoryData, extractedData: editingItem } : item));
        console.log('Editing Item:', editingItem);
        console.log('Form Data:', formData);

        const updatedEditedData = editedData.map(item => 
          item === editingItem ? { ...inventoryData, extractedData: editingItem } : item
        );
        setEditedData(updatedEditedData);

        console.log('Updating existing inventory with ID:', inventoryId, 'Data:', inventoryData);
        await updateInventory(inventoryId, inventoryData);
      }

      // Refresh the data
      await fetchInventories();

      // Close form and clear editing state
      setIsInventoryFormOpen(false);
      setEditingItem(null);

    } catch (error) {
      console.error('Error updating inventory:', error);
    }
  };

  const handleAddClick = (item) => {
    if (!operatorId) {
      setError('Please select an operator first');
      return;
    }

    setSelectedItem(item);
    // Defensive check for centerInventoryData
    const centers = centerInventoryData?.centers || [];
    const existingInventories = centerInventoryData?.existingInventories || [];

    // Safely check if center exists
    const centerExists = Array.isArray(centers) && centers.some(
      center => center.centerName?.toLowerCase().trim() === item.centerName?.toLowerCase().trim()
    );

    // Check if inventory exists
    const matchingInventory = existingInventories.find(inv => 
      inv.inventoryName?.toLowerCase().trim() === item.inventoryName?.toLowerCase().trim() &&
      String(inv.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim()
    );

    setSelectedItem(item);
    // Determine which form to show
    if (!centerExists) {
      console.log('Center does not exist');
      setShowCenterForm(true);
    } else if (!matchingInventory) {
      console.log('Inventory does not exist');
      setShowInventoryForm(true);
    } else {
      setEditingItem(matchingInventory);
      console.log('Editing Item:', editingItem);
      setShowInventoryForm(true);
    }
  };

  const handleCenterFormSubmit = async (formData) => {
    try {
      setIsLoading(true);
      setError(null);

      if (!operatorId) {
        throw new Error('Please select an operator first');
      }
      
      // Generate a new ID for the center
      const centerId = uuidv4();
      
      // Prepare center data with required fields
      const centerData = removeUndefinedFields({
        ...formData,
        id: centerId,
        operatorId: operatorId,
        operatorName: operatorName || '',
        centerName: formData.centerName || '',
        city: formData.city || '',
        micromarket: formData.micromarket || '',
        locality: formData.locality || '',
        landmark: formData.landmark || '',
        centerManagerName: formData.centerManagerName || '',
        centerManagerPhone: formData.centerManagerPhone || '',
        centerManagerEmail: formData.centerManagerEmail || '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      console.log('Saving center data:', centerData);

      // Save center to Firestore
      const centerRef = doc(db, 'centers', centerId);
      await setDoc(centerRef, centerData);

      // Set new center data for subsequent inventory creation
      setNewCenterData(centerData);
      setShowCenterForm(false);
      setShowInventoryForm(true);

      // Optional: Trigger data refresh in parent component
      if (onDataRefresh) {
        onDataRefresh();
      }

      return centerData;
    } catch (error) {
      console.error('Error saving center:', error);
      setError(error.message || 'Failed to save center');
      setIsLoading(false);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleInventoryFormSubmitAdd = async (formData) => {
    try {
      setIsLoading(true);
      setError(null);

      // Ensure we have a center (either existing or newly created)
      const centerId = newCenterData?.id || formData.centerId;
      const centerName = newCenterData?.centerName || formData.centerName;

      if (!centerId) {
        throw new Error('Center information is required');
      }

      // Prepare inventory data
      const inventoryData = removeUndefinedFields({
        ...formData,
        id: uuidv4(),
        operatorId: operatorId,
        operatorName: operatorName,
        centerId: centerId,
        centerName: centerName,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        // Explicitly handle optional fields
        address: formData.address || '',
        size: formData.size || '',
        floor: formData.floor || '',
        inventoryType: formData.inventoryType || '',
        availabilityDate: formData.availabilityDate || '',
        description: formData.description || '',
        quantity: formData.quantity || '1',
        city: formData.city || '',
        locality: formData.locality || '',
        micromarket: formData.micromarket || '',
        landmark: formData.landmark || ''
      });

      // Save inventory to Firestore
      const inventoryRef = doc(db, 'inventories', inventoryData.id);
      await setDoc(inventoryRef, inventoryData);

      const updatedEditedData = editedData.map(item => 
        item === editingItem ? { ...inventoryData, extractedData: editingItem } : item
      );
      setEditedData(updatedEditedData);

      // Reset forms and states
      setShowInventoryForm(false);
      setNewCenterData(null);
      setSelectedItem(null);

      // Optional: Trigger data refresh in parent component
      if (onDataRefresh) {
        onDataRefresh();
      }

      // Close modal after successful submission
      onClose();

      return inventoryData;
    } catch (error) {
      console.error('Error saving inventory:', error);
      setError(error.message || 'Failed to save inventory');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleInventoryFormCloseAdd = () => {
    setShowInventoryForm(false);
    setSelectedItem(null);
    setNewCenterData(null);
    setError(null);
  };

  const handleCenterFormClose = () => {
    setShowCenterForm(false);
    setSelectedItem(null);
    setNewCenterData(null);
    setError(null);
  };

  const handleSaveAll = () => {
    onImport?.({ 
      city: selectedCity, 
      operatorName,
      data: editedData.map((item, index) => ({
        ...item,
        centerId: centerMappings[index],
        inventoryId: inventoryMappings[index]
      }))
    });
    onClose();
  };

  const handleRefreshData = async () => {
    try {
      setIsLoading(true);
      
      // Fetch centers and inventories
      const [centersResponse, inventoriesResponse] = await Promise.all([
        fetchCenters(),
        fetchInventories()
      ]);

      // Extract arrays from response objects
      const centersData = centersResponse.centers || [];
      const inventoriesData = inventoriesResponse.inventories || [];

      // Filter centers by operatorId
      const filteredCenters = centersData.filter(
        center => center.operatorId === operatorId
      );

      // Filter inventories by operatorId
      const filteredInventories = inventoriesData.filter(
        inventory => inventory.operatorId === operatorId
      );

      // Update centers and inventories states
      setCenter(filteredCenters);
      setExtractedInventories(filteredInventories);
      
      // If onDataRefresh prop is provided, call it
      if (onDataRefresh && typeof onDataRefresh === 'function') {
        onDataRefresh({
          centers: filteredCenters,
          inventories: filteredInventories
        });
      }
    } catch (error) {
      console.error('Error refreshing inventory data:', error);
      setError('Failed to refresh data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const removeUndefinedFields = (obj) => {
    const cleanedObj = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] !== undefined && obj[key] !== null) {
        cleanedObj[key] = obj[key];
      }
    });
    return cleanedObj;
  };

 

  useEffect(() => {
    if (open) {
      // Fetch initial data
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const [centersResponse, inventoriesResponse] = await Promise.all([
            fetchCenters(),
            fetchInventories()
          ]);

          // Extract arrays from response objects
          const centersData = centersResponse.centers || [];
          const inventoriesData = inventoriesResponse.inventories || [];

          // Filter centers by operatorId
          const filteredCenters = centersData.filter(
            center => center.operatorId === operatorId
          );
          setCenterMappings(prevMappings => ({
            ...prevMappings,
            ...Object.fromEntries(filteredCenters.map(center => [
              center.centerName?.toLowerCase()?.trim(),
              center
            ]))
          }));

          // Filter inventories by operatorId
          const filteredInventories = inventoriesData.filter(
            inventory => inventory.operatorId === operatorId
          );
          setInventoryMappings(prevMappings => ({
            ...prevMappings,
            ...Object.fromEntries(filteredInventories.map(inventory => [
              inventory.inventoryName?.toLowerCase()?.trim(),
              inventory
            ]))
          }));

          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setError('Failed to load data. Please try again.');
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [open, operatorId]);

 

  useEffect(() => {
    if (editedData.length > 0 && centers.length > 0) {
      const initialMappings = {};
      editedData.forEach((item, index) => {
        const matchedCenter = centers.find(
          center => center.centerName.toLowerCase() === item.centerName.toLowerCase()
        );
        if (matchedCenter) {
          initialMappings[index] = matchedCenter.id;
          // Update editedData with the matched center ID
          setEditedData(prevData => 
            prevData.map((dataItem, dataIndex) => 
              dataIndex === index 
                ? { ...dataItem, centerId: matchedCenter.id }
                : dataItem
            )
          );
        }
      });
      setCenterMappings(initialMappings);
    }
  }, [editedData.length, centers.length]);

  useEffect(() => {
    if (editedData.length > 0 && existingInventories.length > 0) {
      console.log('Matching inventories:', {
        editedData,
        existingInventories: existingInventories
      });

      const initialInventoryMappings = {};
      editedData.forEach((item, index) => {
        // Normalize inventory names for comparison
        const extractedName = item.inventoryName?.toLowerCase().trim();
        const matchedInventory = existingInventories.find(inv => 
          inv.inventoryName?.toLowerCase?.().trim() === extractedName
        );

        console.log('Checking inventory match:', {
          extractedName,
          matchedInventory
        });

        if (matchedInventory) {
          initialInventoryMappings[index] = matchedInventory.id;
          // Update editedData with the matched inventory ID and name
          setEditedData(prevData => 
            prevData.map((dataItem, dataIndex) => 
              dataIndex === index 
                ? { ...dataItem, inventoryId: matchedInventory.id }
                : dataItem
            )
          );
        }
      });

      console.log('Initial inventory mappings:', initialInventoryMappings);
      setInventoryMappings(initialInventoryMappings);
    }
  }, [editedData.length, existingInventories.length]);

  const handleClose = () => {
    setSelectedCity('');
    setSelectedFile(null);
    setPreviewImage(null);
    setImportData([]);
    setEditedData([]);
    setError(null);
    onClose();
  };

  useEffect(() => {
    // Log props for debugging
    console.log('ImportInventoryModal props:', {
      operatorName,
      operatorId,
      centerInventoryData
    });
  }, [operatorName, operatorId, centerInventoryData]);

  const renderExtractedDataTable = () => (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2 
      }}>
        <Typography variant="h6">Extracted Inventory Data</Typography>
        <IconButton 
          color="primary" 
          onClick={handleRefreshData}
          disabled={isLoading}
          title="Refresh Inventory Data"
        >
          {isLoading ? <CircularProgress size={24} /> : <RestartAltIcon />}
        </IconButton>
      </Box>
      
      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Center Name</TableCell>
              <TableCell>Inventory Name</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Availability</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editedData.map((item, index) => {
              const isMatchedCenter = center.some(
                center => center.centerName?.toLowerCase().trim() === item.centerName?.toLowerCase().trim()
              );

              // Find the matching inventory to check size and availability
              const matchingInventory = extractedInventories.find(
                inv => 
                  inv.inventoryName?.toLowerCase().trim() === item.inventoryName?.toLowerCase().trim() &&
                  String(inv.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim()
              );

              const isMatchedInventory = !!matchingInventory;
              const isMatchedSize = matchingInventory?.size?.toString().toLowerCase().trim() === 
                item.size?.toString().toLowerCase().trim();
              const isMatchedAvailability = matchingInventory?.availabilityDate?.toLowerCase().trim() === 
                item.availabilityDate?.toLowerCase().trim();

              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography
                      sx={{
                        borderBottom: `2px solid ${isMatchedCenter ? '#4caf50' : '#f44336'}`,
                        color: isMatchedCenter ? '#4caf50' : '#f44336',
                        display: 'inline-block'
                      }}
                    >
                      {item.centerName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        borderBottom: `2px solid ${isMatchedInventory ? '#4caf50' : '#f44336'}`,
                        color: isMatchedInventory ? '#4caf50' : '#f44336',
                        display: 'inline-block'
                      }}
                    >
                      {item.inventoryName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        borderBottom: `2px solid ${isMatchedSize ? '#4caf50' : '#f44336'}`,
                        color: isMatchedSize ? '#4caf50' : '#f44336',
                        display: 'inline-block'
                      }}
                    >
                      {item.size}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        borderBottom: `2px solid ${isMatchedAvailability ? '#4caf50' : '#f44336'}`,
                        color: isMatchedAvailability ? '#4caf50' : '#f44336',
                        display: 'inline-block'
                      }}
                    >
                      {item.availabilityDate}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton 
                      size="small"
                      onClick={() => {
                        const isExisting = extractedInventories.some(
                          inv => 
                            inv.inventoryName?.toLowerCase?.().trim() === item.inventoryName?.toLowerCase?.().trim() &&
                            String(inv.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim()
                        );
                        if (isExisting) {
                          handleEditClick(item);
                        } else {
                          handleAddClick(item);
                        }
                      }}
                      disabled={isLoading}
                    >
                      {extractedInventories.some(
                        inv => 
                          inv.inventoryName?.toLowerCase?.().trim() === item.inventoryName?.toLowerCase?.().trim() &&
                          String(inv.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim()
                      ) ? (
                        <EditIcon />
                      ) : (
                        <AddIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSaveAll}
        sx={{ mt: 2 }}
        disabled={isLoading}
      >
        Save All
      </Button>
    </Box>
  );

  return (
    <>
      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle>
          Import Inventory for {operatorName}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                mb: 2,
                alignItems: 'center',
                width: '100%'
              }}>
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel>City</InputLabel>
                  <Select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    label="City"
                    size="small"
                  >
                    {cities.map((city) => (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  type="file"
                  inputProps={{
                    accept: "image/*"
                  }}
                  onChange={handleFileSelect}
                  size="small"
                  sx={{ flexGrow: 1 }}
                />

                <Button
                  variant="contained"
                  onClick={handleImport}
                  disabled={!selectedFile || !selectedCity || isLoading}
                  sx={{ height: 40 }}
                >
                  {isLoading ? 'Processing...' : 'Extract'}
                </Button>

                {error && (
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              {previewImage && (
                <>
                <Typography variant="h6" gutterBottom>
                  Uploaded Image
                </Typography>
                <Paper 
                  elevation={3} 
                  sx={{ 
                    p: 1, 
                    height: '500px',
                    overflow: 'hidden'
                  }}
                >
                  <TransformWrapper
                    ref={transformComponentRef}
                    initialScale={1}
                  >
                    {({ zoomIn, zoomOut, resetTransform }) => (
                      <>
                        <Box sx={{ mb: 1 }}>
                        <IconButton onClick={() => zoomIn()}>
                          <ZoomInIcon />
                        </IconButton>
                        <IconButton onClick={() => zoomOut()}>
                          <ZoomOutIcon />
                        </IconButton>
                        <IconButton onClick={() => resetTransform()}>
                          <RestartAltIcon />
                        </IconButton>
                      </Box>
                      <TransformComponent>
                        <img
                          src={previewImage}
                          alt="Preview"
                          style={{ maxWidth: '100%' }}
                        />
                      </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                </Paper>
                </>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                  <CircularProgress />
                </Box>
              ) : (
                editedData.length > 0 && (
                  renderExtractedDataTable()
                )
              )}
            </Grid>
          </Grid>
        </DialogContent>

        {/* Inventory Form Dialog */}
        <InventoryForm
          open={isInventoryFormOpen}
          onClose={handleInventoryFormClose}
          onSubmit={handleInventoryFormSubmit}
          editMode={!!editingItem}
          importInitialData={editingItem}
          theme={theme}
          centers={centers}
          operators={[{ operatorName, id: operatorId }]}
        />
        
        <CenterForm
          open={showCenterForm}
          onClose={handleCenterFormClose}
          onSubmit={handleCenterFormSubmit}
          theme={theme}
          operators={operatorId ? [{ operatorName: operatorName || '', id: operatorId }] : []}
          initialData={{
            centerName: selectedItem?.centerName || '',
            operatorName: operatorName || '',
            operatorId: operatorId || '',
            city: selectedItem?.city || '',
            micromarket: selectedItem?.micromarket || '',
            locality: selectedItem?.locality || '',
          }}
          editMode={false}
        />

        <InventoryForm
          open={showInventoryForm}
          onClose={handleInventoryFormCloseAdd}
          onSubmit={handleInventoryFormSubmitAdd}
          centers={newCenterData ? [...centers, newCenterData] : centers}
          operators={[{ 
            id: operatorId, 
            operatorName: operatorName 
          }]}
          theme={theme}
          initialData={{
            ...selectedItem,
            centerId: newCenterData?.id || selectedItem?.centerId || '',
            operatorName: operatorName || '',
            operatorId: operatorId || '',
            inventoryName: selectedItem?.inventoryName || '',
            size: selectedItem?.size || '',
            availabilityDate: selectedItem?.availabilityDate || '',
          }}
          importInitialData={true}
        />

        {error && (
          <Box 
            sx={{ 
              position: 'fixed', 
              bottom: 20, 
              left: '50%', 
              transform: 'translateX(-50%)',
              bgcolor: 'error.main',
              color: 'white',
              padding: 2,
              borderRadius: 1,
              zIndex: 9999
            }}
          >
            {error}
          </Box>
        )}
      </Dialog>
    </>
  );
};

// Add prop type validation
ImportInventoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onImport: PropTypes.func,
  onEditItem: PropTypes.func,
  operatorName: PropTypes.string,
  centerInventoryData: PropTypes.shape({
    centers: PropTypes.arrayOf(PropTypes.object),
    existingInventories: PropTypes.arrayOf(PropTypes.object)
  }),
  operatorId: PropTypes.string,
  theme: PropTypes.object,
  onDataRefresh: PropTypes.func
};


export default ImportInventoryModal;
