import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage } from 'firebase/storage';


//Dev

const firebaseConfig = {
  apiKey: "AIzaSyDDe-I1Ry9VNCC1qAw817a3fk_-d_aLUNE",
  authDomain: "gofloaters-proposal-dev.firebaseapp.com",
  projectId: "gofloaters-proposal-dev",
  storageBucket: "gofloaters-proposal-dev.firebasestorage.app",
  messagingSenderId: "12087080561",
  appId: "1:12087080561:web:8999b66a1e422926cecdd0",
  measurementId: "G-LQNVBEWSZW"

};


//Prod
/*
const firebaseConfig = {
  apiKey: "AIzaSyDMsWnt0nfElc7Un_Z8_EamoAZxKS9A33k",
  authDomain: "gofloaters-proposals.firebaseapp.com",
  projectId: "gofloaters-proposals",
  storageBucket: "gofloaters-proposals.firebasestorage.app",
  messagingSenderId: "680642423250",
  appId: "1:680642423250:web:f0b14c86a7a6ad8388c00b",
  measurementId: "G-2CM8Q8LCW1"
};
*/
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
 const storage = getStorage(app);
const db = getFirestore(app);
const functions = getFunctions(app, 'us-central1');  // Specify region

// Connect to emulators in development
if (process.env.REACT_APP_ENV === 'development') {
  console.log('Connecting to Firebase emulators...');
  try {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectFunctionsEmulator(functions, 'localhost', 5001);
    console.log('Successfully connected to Firebase emulators');
  } catch (error) {
    console.warn('Error connecting to emulators:', error);
  }
}

export { app, auth, db, functions ,storage};
export default app;
