import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTheme } from '@mui/material/styles';
import PhotoModal from "../../Component/Public/PhotoModal/PhotoModal";
import "../../Styles/Styles.css";

const Details = ({
    location,
    layouts,
    photos,
    virtualTour
}) => {
    const theme = useTheme();
    const [showModal, setShowModal] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [key, setKey] = useState("photos");

    // Function to handle image click to show in the modal
    const handleImageClick = (e, imageUrl, index) => {
        e.stopPropagation(); // Prevent event bubbling
        setCurrentImageIndex(index);
        setShowModal(true);
    };

    // Close modal
    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentImageIndex(0);
    };

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const { lat, lng } = location;

    // Determine available tabs
    const availableTabs = [
        { key: "photos", title: "Photos", hasContent: photos && photos.length > 0 },
        { 
            key: "virtual-tour", 
            title: "Virtual Tour", 
            hasContent: virtualTour && virtualTour.length > 0 
        },
        { key: "layout", title: "Layout", hasContent: layouts && layouts.length > 0 },
        { key: "location", title: "Location", hasContent: lat && lng }
    ].filter(tab => tab.hasContent);

    // Set default tab to the first available tab
    useEffect(() => {
        if (availableTabs.length > 0) {
            setKey(availableTabs[0].key);
        }
    }, []);

    return (
        <div className="my-4">
            <Tabs
                id="media-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                {/* Photos Tab */}
                {availableTabs.find(tab => tab.key === "photos") && (
                    <Tab eventKey="photos" title="Photos">
                        <div style={{ 
                            background: theme.palette.background.paper, 
                            padding: theme.spacing(1.25), 
                            borderRadius: theme.shape.borderRadius 
                        }}>
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                responsive={responsive}
                                ssr={true}
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                keyBoardControl={true}
                                customTransition="transform 300ms ease-in-out"
                                transitionDuration={300}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                deviceType="desktop"
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                {photos?.map((photo, index) => (
                                    <div 
                                        key={index} 
                                        style={{ 
                                            padding: '10px',
                                            position: 'relative'
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '20px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                zIndex: 1,
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                                padding: '4px 12px',
                                                borderRadius: '20px',
                                                color: '#000',
                                                fontWeight: 500,
                                                fontSize: '0.875rem',
                                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                            }}
                                        >
                                            {photo.name || photo.label || `Photo ${index + 1}`}
                                        </div>
                                        <div 
                                            onClick={(e) => handleImageClick(e, photo.url, index)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <img
                                                src={photo.url}
                                                alt={photo.label || `Photo ${index + 1}`}
                                                style={{
                                                    width: '100%',
                                                    height: '400px',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </Tab>
                )}

                {/* Virtual Tour Tab */}
                {availableTabs.find(tab => tab.key === "virtual-tour") && (
                    <Tab eventKey="virtual-tour" title="Virtual Tour">
                        {virtualTour && virtualTour.length > 0 ? (
                            <div>
                                {virtualTour.map((tour, index) => (
                                    <div key={index} className="mb-4">
                                        <h5 className="mb-2">{tour.name}</h5>
                                        {tour.description && (
                                            <p className="text-muted mb-2">{tour.description}</p>
                                        )}
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe
                                                src={tour.url}
                                                width="100%"
                                                height="300px"
                                                allowFullScreen
                                                title={tour.name}
                                                style={{ borderRadius: 18 }}
                                            ></iframe>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-center p-4">
                                <p>No virtual tours available</p>
                            </div>
                        )}
                    </Tab>
                )}

                {/* Layout Tab */}
                {availableTabs.find(tab => tab.key === "layout") && (
                    <Tab eventKey="layout" title="Layout">
                        <Carousel
                            responsive={responsive}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            ssr={true}
                            autoPlay={false}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            containerClass="carousel-container mb-4"
                            dotListClass="custom-dot-list-style"
                        >
                            {layouts?.map((layout, index) => (
                                <div key={index} onClick={(e) => handleImageClick(e, layout?.url, index)} className="layout1">
                                    <img
                                        className="d-block w-100 img-responsive"
                                        src={layout?.url}
                                        alt={layout?.label || `Layout ${index + 1}`}
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </Tab>
                )}

                {/* Location Tab */}
                {availableTabs.find(tab => tab.key === "location") && (
                    <Tab eventKey="location" title="Location">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                                title="Google Map Location"
                                src={`https://www.google.com/maps?q=${lat},${lng}&hl=es;z=14&output=embed`}
                                width="100%"
                                height="300px"
                                allowFullScreen
                                style={{ borderRadius: 18 }}
                            ></iframe>
                        </div>
                    </Tab>
                )}
            </Tabs>

            {/* Photo Modal */}
            <PhotoModal
                isOpen={showModal}
                onClose={handleCloseModal}
                photos={photos}
                initialIndex={currentImageIndex}
            />
        </div>
    );
};

export default Details;
