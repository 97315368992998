import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Modal,
  Select,
  IconButton,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { addOperator } from '../../Services/FirebaseUtils';
import { useMeta } from '../../hooks/useMeta';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase/config';
import { v4 as uuidv4 } from 'uuid';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  maxHeight: '90vh',
  overflow: 'auto'
};

const OperatorForm = ({ open, onClose, onSubmit, initialData, editMode = false, theme }) => {
  const { cities } = useMeta();
  const [formData, setFormData] = useState({
    operatorName: '',
    operatorType: '',
    centralSpocName: '',
    centralSpocPhone: '',
    centralSpocEmail: '',
    corporateProfile: '',
    registeredAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
      country: ''
    },
    deposit: '6',
    lockinPeriod: '24',
    noticePeriod: '6',
    annualEscalation: '7'
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [logos, setLogos] = useState([]);
  const [logoDescriptions, setLogoDescriptions] = useState({});
  const [logoNames, setLogoNames] = useState({});

  useEffect(() => {
    if (editMode && initialData) {
      // Parse the registered address string into object
      let addressParts = {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postalCode: '',
        country: ''
      };

      if (initialData.registeredAddress) {
        if (typeof initialData.registeredAddress === 'string') {
          // If it's a string, split it
          const parts = initialData.registeredAddress.split(',').map(part => part.trim());
          addressParts = {
            line1: parts[0] || '',
            line2: parts[1] || '',
            city: parts[2] || '',
            state: parts[3] || '',
            postalCode: parts[4] || '',
            country: parts[5] || ''
          };
        } else if (typeof initialData.registeredAddress === 'object') {
          // If it's already an object, use it directly
          addressParts = {
            line1: initialData.registeredAddress.line1 || '',
            line2: initialData.registeredAddress.line2 || '',
            city: initialData.registeredAddress.city || '',
            state: initialData.registeredAddress.state || '',
            postalCode: initialData.registeredAddress.postalCode || '',
            country: initialData.registeredAddress.country || ''
          };
        }
      }

      setFormData({
        operatorName: initialData.operatorName || '',
        operatorType: initialData.operatorType || '',
        centralSpocName: initialData.centralSpocName || '',
        centralSpocPhone: initialData.centralSpocPhoneNumber?.toString() || '',
        centralSpocEmail: initialData.centralSpocEmail || '',
        corporateProfile: initialData.corporateProfile || null,
        registeredAddress: addressParts,
        deposit: initialData.deposit?.toString() || '',
        lockinPeriod: initialData.lockinPeriod?.toString() || '',
        noticePeriod: initialData.noticePeriod?.toString() || '',
        annualEscalation: initialData.annualEscalation?.toString() || ''
      });

      // Set logos if they exist
      if (initialData.logos && Array.isArray(initialData.logos)) {
        setLogos(initialData.logos.map(logo => ({
          file: null,
          preview: logo.url
        })));
        
        // Set logo names and descriptions
        const names = {};
        const descriptions = {};
        initialData.logos.forEach((logo, index) => {
          names[index] = logo.name || '';
          descriptions[index] = logo.description || '';
        });
        setLogoNames(names);
        setLogoDescriptions(descriptions);
      }
    }
  }, [editMode, initialData]);

  const [errors, setErrors] = useState({
    operatorName: '',
    operatorType: ''
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      operatorName: '',
      operatorType: ''
    };

    if (!formData.operatorName.trim()) {
      newErrors.operatorName = 'This field is mandatory. Enter a value.';
      isValid = false;
    }

    if (!formData.operatorType) {
      newErrors.operatorType = 'This field is mandatory. Enter a value.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData(prev => ({
      ...prev,
      corporateProfile: file
    }));
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file && logos.length < 2) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogos([...logos, { file, preview: e.target.result }]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoDelete = (index) => {
    const newLogos = [...logos];
    newLogos.splice(index, 1);
    setLogos(newLogos);
    
    const newDescriptions = { ...logoDescriptions };
    const newNames = { ...logoNames };
    delete newDescriptions[index];
    delete newNames[index];
    setLogoDescriptions(newDescriptions);
    setLogoNames(newNames);
  };

  const handleLogoDescriptionChange = (index, value) => {
    setLogoDescriptions({
      ...logoDescriptions,
      [index]: value
    });
  };

  const handleLogoNameChange = (index, value) => {
    setLogoNames({
      ...logoNames,
      [index]: value
    });
  };

  const uploadLogoToStorage = async (file, operatorId, index) => {
    if (!file) return null;
    
    try {
      const fileExtension = file.name.split('.').pop();
      const path = `operator_logo/${operatorId}/logo_${index}.${fileExtension}`;
      const logoRef = storageRef(storage, path);
      
      await uploadBytes(logoRef, file);
      const downloadURL = await getDownloadURL(logoRef);
      
      return downloadURL;
    } catch (error) {
      console.error('Error uploading logo:', error);
      throw new Error('Failed to upload logo');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      setIsLoading(true);
      
      // Format the data before submission
      const { registeredAddress } = formData;
      const operatorId = editMode ? initialData.id : uuidv4();

      // Format registered address as a string
      const formattedAddress = registeredAddress ? 
        `${registeredAddress.line1 || ''}, ${registeredAddress.line2 || ''}, ${registeredAddress.city || ''}, ${registeredAddress.state || ''}, ${registeredAddress.postalCode || ''}, ${registeredAddress.country || ''}`.replace(/,\s*,/g, ',').replace(/^,\s*/, '').replace(/,\s*$/, '') 
        : "";

      // Upload logos to Firebase Storage
      const logoPromises = logos.map((logo, index) => {
        // If we have a file, upload it. If we only have a preview URL (in edit mode), keep the existing URL
        if (logo.file) {
          return uploadLogoToStorage(logo.file, operatorId, index);
        }
        return logo.preview || null;
      });

      const uploadedLogoUrls = await Promise.all(logoPromises);

      // Prepare logo data for Firestore
      const logoData = logos.map((logo, index) => ({
        url: uploadedLogoUrls[index],
        name: logoNames[index] || '',
        description: logoDescriptions[index] || ''
      })).filter(logo => logo.url); // Only include logos that have URLs

      const submissionData = {
        operatorName: formData.operatorName,
        operatorType: formData.operatorType,
        centralSpocName: formData.centralSpocName || "",
        centralSpocPhoneNumber: formData.centralSpocPhone?.replace(/\D/g, '') || "",
        centralSpocEmail: formData.centralSpocEmail || "",
        corporateProfile: formData.corporateProfile || null,
        registeredAddress: formattedAddress,
        deposit: Number(formData.deposit) || 6,
        lockinPeriod: Number(formData.lockinPeriod) || 24,
        noticePeriod: Number(formData.noticePeriod) || 6,
        annualEscalation: Number(formData.annualEscalation) || 7,
        id: operatorId,
        logos: logoData
      };

      if (editMode) {
        await onSubmit(submissionData);
      } else {
        const result = await addOperator(submissionData);
        if (result.success) {
          handleReset();
          onClose();
        } else {
          console.error('Failed to add operator:', result.error);
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setFormData({
      operatorName: '',
      operatorType: '',
      centralSpocName: '',
      centralSpocPhone: '',
      centralSpocEmail: '',
      corporateProfile: '',
      registeredAddress: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postalCode: '',
        country: ''
      },
      deposit: '6',
      lockinPeriod: '24',
      noticePeriod: '6',
      annualEscalation: '7'
    });
    setErrors({
      operatorName: '',
      operatorType: ''
    });
    setLogos([]);
    setLogoDescriptions({});
    setLogoNames({});
  };

  const handleClose = () => {
    handleReset();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="operator-modal-title"
    >
      <Box sx={modalStyle}>
        <Box sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #E5E7EB',
          marginBottom: 2
        }}>
          <Typography variant="h5" component="h3">
            {editMode ? 'Edit Operator' : 'Add Operator'}
          </Typography>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              color: 'text.secondary',
              '&:hover': {
                color: 'text.primary',
                bgcolor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ p: 2 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Basic Information */}
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom required>
                  Operator Name <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={formData.operatorName}
                  error={!!errors.operatorName}
                  onChange={(e) => {
                    setFormData({ ...formData, operatorName: e.target.value });
                    if (errors.operatorName) {
                      setErrors({ ...errors, operatorName: '' });
                    }
                  }}
                />
                {errors.operatorName && (
                  <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                    {errors.operatorName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom required>
                  Operator Type <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                </Typography>
                <FormControl fullWidth size="small" error={!!errors.operatorType}>
                  <Select
                    value={formData.operatorType}
                    onChange={(e) => {
                      setFormData({ ...formData, operatorType: e.target.value });
                      if (errors.operatorType) {
                        setErrors({ ...errors, operatorType: '' });
                      }
                    }}
                    displayEmpty
                    renderValue={value => value || '-Select-'}
                  >
                    <MenuItem value="" disabled>-Select-</MenuItem>
                    <MenuItem value="Coworking">Coworking</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                    <MenuItem value="Managed">Managed</MenuItem>
                  </Select>
                  {errors.operatorType && (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                      {errors.operatorType}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              {/* SPOC Information */}
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Central SPOC Name
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={formData.centralSpocName}
                  onChange={(e) => setFormData({ ...formData, centralSpocName: e.target.value })}
                  placeholder="First Name"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Central SPOC Phone Number
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={formData.centralSpocPhone}
                  onChange={(e) => setFormData({ ...formData, centralSpocPhone: e.target.value })}
                  placeholder="81234 56789"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Central SPOC Email
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="email"
                  value={formData.centralSpocEmail}
                  onChange={(e) => setFormData({ ...formData, centralSpocEmail: e.target.value })}
                />
              </Grid>

              {/* Registered Address */}
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Registered Address
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Address Line 1"
                      value={formData.registeredAddress.line1}
                      onChange={(e) => setFormData({
                        ...formData,
                        registeredAddress: { ...formData.registeredAddress, line1: e.target.value }
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Address Line 2"
                      value={formData.registeredAddress.line2}
                      onChange={(e) => setFormData({
                        ...formData,
                        registeredAddress: { ...formData.registeredAddress, line2: e.target.value }
                      })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                      <Select
                        value={formData.registeredAddress.city}
                        onChange={(e) => setFormData({
                          ...formData,
                          registeredAddress: { ...formData.registeredAddress, city: e.target.value }
                        })}
                        displayEmpty
                        renderValue={value => value || '-Select City-'}
                      >
                        <MenuItem value="" disabled>-Select City-</MenuItem>
                        {cities.map((city) => (
                          <MenuItem key={city} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="State / Province"
                      value={formData.registeredAddress.state}
                      onChange={(e) => setFormData({
                        ...formData,
                        registeredAddress: { ...formData.registeredAddress, state: e.target.value }
                      })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Postal Code"
                      value={formData.registeredAddress.postalCode}
                      onChange={(e) => setFormData({
                        ...formData,
                        registeredAddress: { ...formData.registeredAddress, postalCode: e.target.value }
                      })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                      <Select
                        value={formData.registeredAddress.country}
                        onChange={(e) => setFormData({
                          ...formData,
                          registeredAddress: { ...formData.registeredAddress, country: e.target.value }
                        })}
                        displayEmpty
                        renderValue={value => value || '-Select-'}
                      >
                        <MenuItem value="" disabled>-Select-</MenuItem>
                        <MenuItem value="IN">India</MenuItem>
                        <MenuItem value="US">United States</MenuItem>
                        <MenuItem value="UK">United Kingdom</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* Logo Upload Section */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Company Logos
                </Typography>
                <Box sx={{ mb: 2 }}>
                  {logos.map((logo, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 2,
                        p: 2,
                        border: '1px solid #e0e0e0',
                        borderRadius: 1
                      }}
                    >
                      <Box
                        component="img"
                        src={logo.preview}
                        alt={`Logo ${index + 1}`}
                        sx={{
                          width: 100,
                          height: 100,
                          objectFit: 'contain',
                          borderRadius: 1
                        }}
                      />
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                              Logo Name
                            </Typography>
                            <TextField
                              fullWidth
                              size="small"
                              value={logoNames[index] || ''}
                              onChange={(e) => handleLogoNameChange(index, e.target.value)}
                              placeholder="Enter logo name"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                              Logo Description
                            </Typography>
                            <TextField
                              fullWidth
                              size="small"
                              value={logoDescriptions[index] || ''}
                              onChange={(e) => handleLogoDescriptionChange(index, e.target.value)}
                              placeholder="Enter logo description"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <IconButton
                        onClick={() => handleLogoDelete(index)}
                        sx={{ color: 'error.main' }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
                {logos.length < 2 && (
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mt: 1 }}
                  >
                    Upload Logo
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleLogoUpload}
                    />
                  </Button>
                )}
              </Grid>

              {/* Standard Terms */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'text.primary', mt: 2, mb: 2 }}>
                  Standard Terms
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Deposit
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={formData.deposit}
                      onChange={(e) => setFormData({ ...formData, deposit: e.target.value })}
                      placeholder="######"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Lockin Period
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={formData.lockinPeriod}
                      onChange={(e) => setFormData({ ...formData, lockinPeriod: e.target.value })}
                      placeholder="######"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Notice Period
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={formData.noticePeriod}
                      onChange={(e) => setFormData({ ...formData, noticePeriod: e.target.value })}
                      placeholder="######"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Annual Escalation
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={formData.annualEscalation}
                      onChange={(e) => setFormData({ ...formData, annualEscalation: e.target.value })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Submit Buttons */}
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  borderColor: theme?.palette?.grey[300],
                  color: theme?.palette?.text?.secondary,
                  '&:hover': {
                    borderColor: theme?.palette?.grey[400],
                    bgcolor: 'transparent'
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: theme?.palette?.primary?.main,
                  color: 'white',
                  '&:hover': {
                    bgcolor: theme?.palette?.primary?.dark
                  }
                }}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : (editMode ? 'Save Changes' : 'Add Operator')}
              </Button>
              {error && (
                <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                  {error}
                </Typography>
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default OperatorForm;
