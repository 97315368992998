import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { Box, Tabs, Tab } from '@mui/material';
import "bootstrap/dist/css/bootstrap.min.css";

const containerStyle = {
    width: '100%',
    height: '50vh',
    border: "2px solid #ddd"
};

// Custom map styles
const mapStyles = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e5e5e5"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dadada"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e5e5e5"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ADD8E6"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    }
];

const mapOptions = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

const formatPrice = (price) =>
    price !== undefined && price !== null
        ? new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            maximumFractionDigits: 0,
        }).format(price)
        : "N/A";



export default function VistMap({ proposal, selectedTab, scheduleData }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBFzQyh0__KlIMKsmGqMIsnsolOeXTYoUs',
    });
    const [map, setMap] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [zoom, setZoom] = useState(13);




    // Filter options based on selected tab
    const locations = scheduleData.map(schedule => {
        // Find matching proposal option using centerName
        const matchingOption = proposal?.proposedOptions?.find(
            option => option.centerName === schedule.option
        );

        console.log('Schedule:', schedule);
        console.log('Matching option:', matchingOption);

        if (matchingOption?.location) {
            const result = {
                lat: Number(matchingOption.location.lat),
                lng: Number(matchingOption.location.lng),
                centerName: schedule.option,
                locality: schedule.address,
                billableSeats: matchingOption.billableSeats,
                pricePerSeat: formatPrice(matchingOption.pricePerSeat),
                availableFrom: matchingOption.availableFrom,
                visitDate: schedule.date?.format('DD/MM/YYYY'),
                visitTime: schedule.time?.format('HH:mm')
            };
            console.log('Created location object:', result);
            return result;
        }
        return null;
    }).filter(Boolean);
    const [center, setCenter] = useState(() => {
        if (locations.length > 0) {
            return { lat: Number(locations[0].lat), lng: Number(locations[0].lng) };
        }
        return { lat: 13.0827, lng: 80.2707 };
    });
    const onLoad = useCallback((map) => {
        if (locations.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            locations.forEach((location) => {
                bounds.extend(new window.google.maps.LatLng(Number(location.lat), Number(location.lng)));
            });
            map.fitBounds(bounds);
        }
        setMap(map);
    }, [locations]);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const handleTouchStart = (event, rowId) => {
        if (map && locations[rowId]) {
            map.panTo({
                lat: Number(locations[rowId].lat),
                lng: Number(locations[rowId].lng)
            });
            setSelectedMarker(locations[rowId]);
        }
    };

    return isLoaded ? (
        <div>
            <div className="col-lg-12 h-25 col-md-12 mb-3">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={mapOptions}
                >
                    {locations.map((location, index) => (
                        <MarkerF
                            key={index}
                            position={{ lat: Number(location.lat), lng: Number(location.lng) }}
                            onClick={() => setSelectedMarker(location)}
                            options={{ clickable: true }}
                            label={`${index + 1}`}
                        />
                    ))}
                    {selectedMarker && (
                        <InfoWindowF
                            key={`info-window-${selectedMarker.lat}-${selectedMarker.lng}`}
                            position={{ lat: Number(selectedMarker.lat), lng: Number(selectedMarker.lng) }}
                            onCloseClick={() => setSelectedMarker(null)}
                        >
                            <div>
                                <h5>{selectedMarker.centerName}</h5>
                                <p>{selectedMarker.locality}</p>
                                <p>Price Per Seat : {selectedMarker.pricePerSeat}</p>
                                <p>Billable Seats : {selectedMarker.billableSeats}</p>
                                <p>Available From : {selectedMarker.availableFrom}</p>
                            </div>
                        </InfoWindowF>
                    )}
                </GoogleMap>
            </div>
        </div>) :
        <div>Loading...</div>

}