import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Card,
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  useTheme,
  TextField,
  FormControl,
  InputLabel,
  Select,
  InputAdornment
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { deleteActionItem, updateActionItem } from '../../../Services/FirebaseUtils';
import ActionItemForm from '../../../Component/Private/ActionItemForm';

const ActionItems = () => {
  const theme = useTheme();
  const { items: actionItems } = useSelector((state) => state.actionItems);
  
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleAssigneeFilterChange = (event) => {
    setAssigneeFilter(event.target.value);
    setPage(0);
  };

  const handleAddClick = () => {
    setSelectedItem(null);
    setOpenDialog(true);
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  const handleMenuClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handleDelete = async () => {
    if (!selectedItem) return;

    try {
      await deleteActionItem(selectedItem.id);
      handleMenuClose();
    } catch (error) {
      console.error('Error deleting action item:', error);
    }
  };

  const handleStatusChange = async (newStatus) => {
    if (!selectedItem) return;

    try {
      await updateActionItem(selectedItem.id, { status: newStatus });
      handleMenuClose();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate ? moment(timestamp.toDate()) : moment(timestamp);
    return date.format('MMM D, YYYY h:mm A');
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return theme.palette.success.main;
      case 'in_progress':
        return theme.palette.warning.main;
      case 'open':
      default:
        return theme.palette.info.main;
    }
  };

  const filteredItems = actionItems.filter((item) => {
    const matchesSearch = 
      item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.leadName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.clientName?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesAssignee = !assigneeFilter || item.assignedTo === assigneeFilter;
    return matchesSearch && matchesAssignee;
  });

  return (
    <Card sx={{ borderRadius: 1 }}>
      <Box sx={{ px: 2, py: 1 }}>        
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            placeholder="Search action items..."
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ flexGrow: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Filter by Assignee</InputLabel>
            <Select
              value={assigneeFilter}
              onChange={handleAssigneeFilterChange}
              label="Filter by Assignee"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="user">Me</MenuItem>
              <MenuItem value="space_partner">Space Partner</MenuItem>
              <MenuItem value="lead">Lead</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddClick}
          >
            Add Action Item
          </Button>
        </Box>
 {/* Separator Line */}
 <Box sx={{
          height: '1px',
          backgroundColor: '#e0e0e0',
          width: '100%',
          mx: -2,
          width: 'calc(100% + 32px)',
          mb: 2
        }} />
        <TableContainer>
        <Table size="small">
            <TableHead>
            <TableRow sx={{ backgroundColor: (theme) => `${theme.palette.primary.main}08` }}>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Linked To</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{formatDate(item.dueDate)}</TableCell>
                    <TableCell>
                      {item.assignedTo === 'user' ? 'Me' :
                       item.assignedTo === 'space_partner' ? 'Space Partner' :
                       item.assignedTo === 'lead' ? 'Lead' : ''}
                    </TableCell>
                    <TableCell>
                      {item.leadId ? (
                        <Chip 
                          label={`Lead: ${item.leadName || 'Unnamed Lead'}`}
                          size="small"
                          color="primary"
                          variant="outlined"
                        />
                      ) : item.proposalId ? (
                        <Chip 
                          label={`Proposal: ${item.proposalName || 'Unnamed Client'}`}
                          size="small"
                          color="secondary"
                          variant="outlined"
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={item.status?.replace('_', ' ')}
                        size="small"
                        sx={{
                          fontSize: '0.75rem',
                          fontWeight: 500,
                          textTransform: 'capitalize',
                          color: getStatusColor(item.status)
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleEditClick(item)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => handleMenuClick(e, item)}
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </TableContainer>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleStatusChange('open')}>
            Mark as Open
          </MenuItem>
          <MenuItem onClick={() => handleStatusChange('in_progress')}>
            Mark as In Progress
          </MenuItem>
          <MenuItem onClick={() => handleStatusChange('completed')}>
            Mark as Completed
          </MenuItem>
          <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
            Delete
          </MenuItem>
        </Menu>

        <ActionItemForm
          open={openDialog}
          onClose={handleDialogClose}
          item={selectedItem}
        />
      </Box>
    </Card>
  );
};

export default ActionItems;
