import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Box,
    Typography,
    DialogContentText,
    Divider,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebase/config';
import { v4 as uuidv4 } from 'uuid';

const EditCenterDialog = ({ open, onClose, center, onSave, onDelete }) => {
    const formatPrice = (price) =>
        price !== undefined && price !== null
            ? new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                maximumFractionDigits: 0,
            }).format(price).replace("₹", "").trim()
            : "";

    const [formData, setFormData] = useState({
        centerName: '',
        locality: '',
        pricePerSeat: '',
        billableSeats: '',
        totalRentPerMont: '',
        securityDeposit: '',
        carParkingCost: '',
        bikeParkingCost: '',
        noticePeriod: '',
        lockinPeriod: '',
        availableFrom: '',
        annualEscalation: '',
        securityDepositMonths: '',
        complimentaryBikeParkSlots: '',
        complimentaryCarParkSlots: '',
        complimentaryMeetingRoomCredits: '',
        customizationCost: '',
        inventoryDescription: '',
        operationHours: '',
        isRecommended: false
    });

    const [layouts, setLayouts] = useState([]);
    const [layoutDescriptions, setLayoutDescriptions] = useState({});
    const [layoutTypes, setLayoutTypes] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Photo type options
    const photoTypes = [
        "Floor Plan",
        "Office Plan ",
        "Other Type ",
    ];

    useEffect(() => {
        if (center) {
            setFormData({
                centerName: center.centerName || '',
                locality: center.locality || '',
                pricePerSeat: center.pricePerSeat || '',
                billableSeats: center.billableSeats || '',
                totalRentPerMont: center.totalRentPerMont || '',
                securityDeposit: center.securityDeposit || '',
                carParkingCost: center.carParkingCost || '',
                bikeParkingCost: center.bikeParkingCost || '',
                noticePeriod: center.noticePeriod || '',
                lockinPeriod: center.lockinPeriod || '',
                availableFrom: center.availableFrom || '',
                annualEscalation: center.annualEscalation || '',
                securityDepositMonths: center.securityDepositMonths || '',
                complimentaryBikeParkSlots: center.complimentaryBikeParkSlots || '',
                complimentaryCarParkSlots: center.complimentaryCarParkSlots || '',
                complimentaryMeetingRoomCredits: center.complimentaryMeetingRoomCredits || '',
                customizationCost: center.customizationCost || '',
                inventoryDescription: center.inventoryDescription || '',
                operationHours: center.operationHours || '',
                isRecommended: center.isRecommended || false
            });

            if (center.layouts) {
                // Initialize layouts from existing data
                setLayouts(center.layouts.map(layout => ({
                    id: layout.id,
                    centerId: layout.centerId,
                    file: null,
                    preview: layout.url
                })));

                // Initialize descriptions and types
                const descriptions = {};
                const types = {};
                center.layouts.forEach((layout, index) => {
                    descriptions[index] = layout.description || '';
                    types[index] = layout.type || '';
                });
                setLayoutDescriptions(descriptions);
                setLayoutTypes(types);
            }
        }
    }, [center]);

    const handleChange = (field) => (event) => {
        let value = event.target.value;

        // For price fields, ensure we're working with strings and handle currency/commas
        if (['pricePerSeat', 'totalRentPerMont', 'securityDeposit', 'carParkingCost', 'bikeParkingCost', 'customizationCost'].includes(field)) {
            value = String(value).replace(/[₹,]/g, '');
        }

        const updatedData = {
            ...formData,
            [field]: value
        };

        // Calculate total rent when price per seat or billable seats changes
        if (field === 'pricePerSeat' || field === 'billableSeats') {
            const pricePerSeat = field === 'pricePerSeat' ? Number(value) : Number(formData.pricePerSeat);
            const billableSeats = field === 'billableSeats' ? Number(value) : Number(formData.billableSeats);

            if (!isNaN(pricePerSeat) && !isNaN(billableSeats)) {
                updatedData.totalRentPerMont = String(pricePerSeat * billableSeats);
            }
        }

        // Calculate security deposit when price per seat or months change
        if (field === 'pricePerSeat' || field === 'securityDepositMonths') {
            const pricePerSeat = field === 'pricePerSeat' ? Number(value) : Number(formData.pricePerSeat);
            const months = field === 'securityDepositMonths' ? Number(value) : Number(formData.securityDepositMonths);
            const billableSeats = field === 'billableSeats' ? Number(value) : Number(formData.billableSeats);

            if (!isNaN(pricePerSeat) && !isNaN(months)) {
                updatedData.securityDeposit = String(pricePerSeat * months * billableSeats);
            }
        }

        setFormData(updatedData);
    };

    const handleCheckboxChange = (event) => {
        setFormData({
            ...formData,
            isRecommended: event.target.checked
        });
    };

    // Handle layout file selection
    const handleLayoutUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const centerId = formData.centerName;
            const photoId = uuidv4();
            setLayouts([...layouts, {
                id: photoId,
                centerId: centerId,
                file,
                preview: URL.createObjectURL(file)
            }]);
        }
    };

    // Handle layout deletion
    const handleLayoutDelete = (index) => {
        const newLayouts = [...layouts];
        newLayouts.splice(index, 1);
        setLayouts(newLayouts);

        const newDescriptions = { ...layoutDescriptions };
        const newTypes = { ...layoutTypes };
        delete newDescriptions[index];
        delete newTypes[index];
        setLayoutDescriptions(newDescriptions);
        setLayoutTypes(newTypes);
    };

    // Handle layout description change
    const handleDescriptionChange = (index, value) => {
        setLayoutDescriptions({
            ...layoutDescriptions,
            [index]: value
        });
    };

    // Handle layout type change
    const handleTypeChange = (index, value) => {
        setLayoutTypes({
            ...layoutTypes,
            [index]: value
        });
    };

    // Upload layout to Firebase Storage
    const uploadLayoutToStorage = async (file, centerId, index) => {
        if (!file) return null;

        try {
            const fileExtension = file.name.split('.').pop();
            const path = `center_layouts/${centerId}/layout_${index}.${fileExtension}`;
            const layoutRef = storageRef(storage, path);

            await uploadBytes(layoutRef, file);
            const downloadURL = await getDownloadURL(layoutRef);

            return downloadURL;
        } catch (error) {
            console.error('Error uploading layout:', error);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Helper function to safely convert to number
        const toNumber = (value) => {
            if (value === null || value === undefined || value === '') return 0;
            if (typeof value === 'number') return value;
            return Number(String(value).replace(/[₹,]/g, ''));
        };

        // Convert all values to appropriate types
        const processedData = {
            ...formData,
            pricePerSeat: toNumber(formData.pricePerSeat),
            totalRentPerMont: toNumber(formData.totalRentPerMont),
            securityDeposit: toNumber(formData.securityDeposit),
            carParkingCost: toNumber(formData.carParkingCost),
            bikeParkingCost: toNumber(formData.bikeParkingCost),
            customizationCost: toNumber(formData.customizationCost),
            annualEscalation: toNumber(formData.annualEscalation),
            complimentaryBikeParkSlots: toNumber(formData.complimentaryBikeParkSlots),
            complimentaryCarParkSlots: toNumber(formData.complimentaryCarParkSlots),
            complimentaryMeetingRoomCredits: toNumber(formData.complimentaryMeetingRoomCredits),
            billableSeats: toNumber(formData.billableSeats),
            noticePeriod: toNumber(formData.noticePeriod),
            lockinPeriod: toNumber(formData.lockinPeriod),
            securityDepositMonths: toNumber(formData.securityDepositMonths),
            // Keep string fields as is
            centerName: formData.centerName || '',
            locality: formData.locality || '',
            inventoryDescription: formData.inventoryDescription || '',
            operationHours: formData.operationHours || '',
            isRecommended: formData.isRecommended || false
        };

        try {
            setIsLoading(true);
            setError(null);

            const centerId = formData.centerName;

            // Upload layouts and get their URLs
            const uploadedLayoutUrls = await Promise.all(
                layouts
                    .filter(layout => !layout.centerId || layout.centerId === centerId) // Only process layouts for this center
                    .map((layout, index) => {
                        if (layout.file) {
                            return uploadLayoutToStorage(layout.file, centerId, index);
                        }
                        return layout.preview || null;
                    })
            );

            // Filter out null values
            const validLayoutUrls = uploadedLayoutUrls.filter(url => url !== null);

            // Prepare layout data with centerId
            const layoutData = layouts
                .filter(layout => !layout.centerId || layout.centerId === centerId) // Only include layouts for this center
                .map((layout, index) => ({
                    url: uploadedLayoutUrls[index],
                    type: layoutTypes[index] || '',
                    description: layoutDescriptions[index] || ''
                }))
                .filter(layout => layout.url !== null);

            // Update the form data with layouts
            const updatedFormData = {
                ...processedData,
                id: centerId,
                layouts: layoutData
            };

            await onSave(updatedFormData);
            onClose();
        } catch (error) {
            console.error('Error submitting form:', error);
            setError(error.message || 'Failed to submit form');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = () => {
        if (onDelete && center) {
            onDelete(center);
        }
        setDeleteDialogOpen(false);
        onClose();
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        Edit Option
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.isRecommended}
                                        onChange={handleCheckboxChange}
                                        color="primary"
                                    />
                                }
                                label="Mark as Recommended Option"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Center Name"
                                value={formData.centerName}
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                    },
                                    backgroundColor: '#f5f5f5'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Price per Seat"
                                value={formatPrice(formData.pricePerSeat)}
                                onChange={handleChange('pricePerSeat')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Billable Seats"
                                type="number"
                                value={formData.billableSeats}
                                onChange={handleChange('billableSeats')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Total Rent Per Month"
                                value={formatPrice(formData.totalRentPerMont)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                    readOnly: true,
                                }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                    },
                                    backgroundColor: '#f5f5f5'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Location"
                                value={formData.locality}
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                    },
                                    backgroundColor: '#f5f5f5'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Security Deposit Months"
                                type="number"
                                value={formData.securityDepositMonths}
                                onChange={handleChange('securityDepositMonths')}
                                inputProps={{ min: 0 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Security Deposit"
                                value={formatPrice(formData.securityDeposit)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                    readOnly: true,
                                }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                    },
                                    backgroundColor: '#f5f5f5'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Notice Period (Months)"
                                type="number"
                                value={formData.noticePeriod}
                                onChange={handleChange('noticePeriod')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Lock-in Period (Months)"
                                type="number"
                                value={formData.lockinPeriod}
                                onChange={handleChange('lockinPeriod')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Car Parking Cost"
                                value={formatPrice(formData.carParkingCost)}
                                onChange={handleChange('carParkingCost')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Bike Parking Cost"
                                value={formatPrice(formData.bikeParkingCost)}
                                onChange={handleChange('bikeParkingCost')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Annual Escalation (%)"
                                type="number"
                                value={formData.annualEscalation}
                                onChange={handleChange('annualEscalation')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Complimentary Bike Park Slots"
                                type="number"
                                value={formData.complimentaryBikeParkSlots}
                                onChange={handleChange('complimentaryBikeParkSlots')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Complimentary Car Park Slots"
                                type="number"
                                value={formData.complimentaryCarParkSlots}
                                onChange={handleChange('complimentaryCarParkSlots')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Complimentary Meeting Room Credits"
                                value={formData.complimentaryMeetingRoomCredits}
                                onChange={handleChange('complimentaryMeetingRoomCredits')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Customization Cost"
                                value={formatPrice(formData.customizationCost)}
                                onChange={handleChange('customizationCost')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Inventory Description"
                                value={formData.inventoryDescription}
                                onChange={handleChange('inventoryDescription')}
                                multiline
                                rows={3}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Operation Hours"
                                value={formData.operationHours}
                                onChange={handleChange('operationHours')}
                            />
                        </Grid>
                    </Grid>

                    {/* Layout Upload Section */}
                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                        Inventory layouts
                    </Typography>
                    <Grid container spacing={2}>
                        {layouts
                            .filter(layout => !layout.centerId || layout.centerId === formData.centerName)
                            .map((layout, index) => (
                                <Grid item xs={12} key={index}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                        <Box
                                            component="img"
                                            src={layout.preview}
                                            alt={`Layout ${index + 1}`}
                                            sx={{
                                                width: 100,
                                                height: 100,
                                                objectFit: 'cover',
                                                borderRadius: 1
                                            }}
                                        />
                                        <FormControl sx={{ minWidth: 150 }}>
                                            <InputLabel>Photo Type</InputLabel>
                                            <Select
                                                value={layoutTypes[index] || ''}
                                                onChange={(e) => handleTypeChange(index, e.target.value)}
                                                label="Photo Type"
                                                size="small"
                                            >
                                                {photoTypes.map((type) => (
                                                    <MenuItem key={type} value={type}>
                                                        {type}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Description"
                                            value={layoutDescriptions[index] || ''}
                                            onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                        />
                                        <IconButton
                                            onClick={() => handleLayoutDelete(index)}
                                            color="error"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            ))}
                        {layouts.length < 10 && (
                            <Grid item xs={12}>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    startIcon={<AddPhotoAlternateIcon />}
                                    sx={{ mt: 1 }}
                                >
                                    Add Photo
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={handleLayoutUpload}
                                    />
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setDeleteDialogOpen(true)}
                        startIcon={<DeleteIcon />}
                        disabled={isLoading}
                        sx={{
                            textTransform: 'none',
                            borderColor: '#d32f2f',
                            minWidth: '120px',
                            '&:hover': {
                                borderColor: '#d32f2f',
                                backgroundColor: 'rgba(211, 47, 47, 0.04)'
                            }
                        }}
                    >
                        Delete Option
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        sx={{
                            backgroundColor: (theme) => theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: (theme) => theme.palette.primary.dark,
                            },
                            minWidth: '120px'
                        }}
                    >
                        {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Save Changes'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => !isLoading && setDeleteDialogOpen(false)}
            >
                <DialogTitle>Delete Center</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this center? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} disabled={isLoading}>No</Button>
                    <Button
                        onClick={handleDelete}
                        color="error"
                        disabled={isLoading}
                        autoFocus
                    >
                        {isLoading ? (
                            <CircularProgress size={20} color="error" />
                        ) : (
                            'Yes, Delete'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditCenterDialog;
