import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    IconButton,
    Typography,
    Divider,
    CircularProgress,
    Popover,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { searchInventory } from '../../../Services/Service';

const cities = [
    'Chennai',
    'Bangalore',
    'Hyderabad',
    'Mumbai',
    'Pune',
    'Kochi',
    'Coimbatore'
];

const micromarkets = {
    'Chennai': ['Guindy', 'OMR', 'Anna Nagar', 'T Nagar','Chennai CBD'],
    'Bangalore': ['Koramangala', 'Indiranagar', 'Whitefield', 'HSR Layout','Bangalore CBD'],
    'Hyderabad': ['Hitech City', 'Gachibowli', 'Madhapur'],
    'Mumbai': ['Andheri', 'Bandra', 'Lower Parel'],
    'Pune': ['Baner', 'Hinjewadi', 'Kharadi'],
    'Kochi': ['Kakkanad', 'MG Road', 'Palarivattom'],
    'Coimbatore': ['RS Puram', 'Race Course', 'Peelamedu']
};

const SearchCenterDialog = ({ open, onClose, onAddToProposal }) => {
    const [formData, setFormData] = useState({
        city: 'Chennai',
        micromarket: 'Guindy',
        budgetFrom: '10000',
        budgetTo: '30000',
        seatsNeeded: '50',
        expectedStart: null
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => {
            if (name === 'city') {
                return {
                    ...prev,
                    [name]: value,
                    micromarket: ''
                };
            }
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const handleDateChange = (date) => {
        setFormData(prev => ({
            ...prev,
            expectedStart: date
        }));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = async () => {
        setLoading(true);
        setError(null);
        
        const result = await searchInventory(formData);
        
        if (result.success) {
            setSearchResults(result.data);
        } else {
            setError(result.error);
        }
        
        setLoading(false);
    };

    const handleClear = () => {
        setFormData({
            city: '',
            micromarket: '',
            budgetFrom: '',
            budgetTo: '',
            seatsNeeded: '',
            expectedStart: null
        });
        setSearchResults([]);
        setError(null);
        setPage(0);
    };

    const handleMenuClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };

    const handleViewDetails = () => {
        console.log('View details for:', selectedRow);
        handleMenuClose();
    };

    const handleAddToProposal = (isRecommended) => {
        if (onAddToProposal) {
            const centerWithRecommendedStatus = {
                ...selectedRow,
                isRecommended: isRecommended
            };
            
            onAddToProposal(centerWithRecommendedStatus);
        }
        handleMenuClose();
        onClose();
    };

    // Get current page data
    const currentPageData = searchResults.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    Search Options
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>

                    {/* First Row */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required size="small">
                            <InputLabel>City Master</InputLabel>
                            <Select
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                label="City Master"
                                IconComponent={KeyboardArrowDownIcon}
                            >
                                {cities.map((city) => (
                                    <MenuItem key={city} value={city}>
                                        {city}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required size="small">
                            <InputLabel>Micromarket</InputLabel>
                            <Select
                                name="micromarket"
                                value={formData.micromarket}
                                onChange={handleChange}
                                label="Micromarket"
                                disabled={!formData.city}
                                IconComponent={KeyboardArrowDownIcon}
                            >
                                {formData.city && micromarkets[formData.city].map((market) => (
                                    <MenuItem key={market} value={market}>
                                        {market}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Second Row */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label="Budget From"
                            name="budgetFrom"
                            value={formData.budgetFrom}
                            onChange={handleChange}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label="Budget To"
                            name="budgetTo"
                            value={formData.budgetTo}
                            onChange={handleChange}
                            type="number"
                        />
                    </Grid>

                    {/* Third Row */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label="Seats Needed"
                            name="seatsNeeded"
                            value={formData.seatsNeeded}
                            onChange={handleChange}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Expected Start"
                                value={formData.expectedStart}
                                onChange={handleDateChange}
                                format="DD-MMM-YYYY"
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        required: true,
                                        size: "small"
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    {/* Buttons */}
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>

                            <Button
                                variant="outlined"
                                onClick={handleClear}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSearch}
                                sx={{ minWidth: 100 }}
                            >
                                Search
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                {/* Search Results */}
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Search Results
                    </Typography>
                    {error && (
                        <Typography color="error" sx={{ mb: 2 }}>
                            {error}
                        </Typography>
                    )}
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Operator
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Center Name
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Inventory
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Billable Seats
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        Availability Date
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Price Per Seat
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Micromarket
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Locality
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Actions
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={10} align="center" sx={{ py: 3 }}>
                                            <CircularProgress size={24} />
                                        </TableCell>
                                    </TableRow>
                                ) : searchResults.length > 0 ? (
                                    currentPageData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.operatorName}</TableCell>
                                            <TableCell>{row.centerName}</TableCell>
                                            <TableCell>{row.inventory}</TableCell>
                                            <TableCell>{row.billableSeats}</TableCell>
                                            <TableCell>{row.availabilityDate}</TableCell>
                                            <TableCell>{row.pricePerSeat}</TableCell>
                                            <TableCell>{row.microMarket}</TableCell>
                                            <TableCell>{row.locality}</TableCell>
                                            <TableCell>
                                                <IconButton 
                                                    size="small"
                                                    onClick={(event) => handleMenuClick(event, row)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={10} align="center">
                                            No results found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        {searchResults.length > 0 && (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <TablePagination
                                    component="div"
                                    count={searchResults.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    labelRowsPerPage="Rows per page:"
                                    labelDisplayedRows={({ from, to, count }) => `${from}–${to} of ${count}`}
                                    sx={{
                                        '.MuiTablePagination-displayedRows': {
                                            margin: '0 20px'
                                        },
                                        '.MuiTablePagination-selectLabel': {
                                            marginRight: '10px'
                                        }
                                    }}
                                />
                            </Box>
                        )}
                    </TableContainer>
                </Box>
            </DialogContent>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{ width: 250 }}>
                    <ListItem button onClick={handleViewDetails}>
                        <ListItemText primary="View Details" />
                    </ListItem>
                    <ListItem button onClick={() => handleAddToProposal(true)}>
                        <ListItemText 
                            primary="Add as Recommended Option" 
                        />
                    </ListItem>
                    <ListItem button onClick={() => handleAddToProposal(false)}>
                        <ListItemText 
                            primary="Add as Other Option" 
                        />
                    </ListItem>
                </List>
            </Popover>
        </Dialog>
    );
};

export default SearchCenterDialog;
