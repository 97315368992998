import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, useTheme, useMediaQuery, AppBar, Divider } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { setActionItems, setLoading as setActionItemsLoading, setError as setActionItemsError } from '../../../redux/slices/actionItemsSlice';
import { setLeads, setLoading as setLeadsLoading, setError as setLeadsError } from '../../../redux/slices/leadsSlice';
import { setProposals, setLoading as setProposalsLoading, setError as setProposalsError } from '../../../redux/slices/proposalsSlice';
import { setUser, setLoading as setUserLoading, setError as setUserError } from '../../../redux/slices/userSlice';
import { setMeta, setLoading as setMetaLoading, setError as setMetaError } from '../../../redux/slices/metaSlice';
import { 
  setupActionItemsListener, 
  setupLeadsListener, 
  setupProposalsListener, 
  setupUserListener,
  fetchMetaDocument 
} from '../../../Services/FirebaseUtils';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const drawerWidth = 240;  

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState('Dashboard');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const auth = getAuth();
  const userData = useSelector(state => state.user.data);
  const metaLoading = useSelector(state => state.meta.loading);

  // Auth state change listener
  useEffect(() => {
    dispatch(setUserLoading(true));
    
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Set up user listener first
          const userUnsubscribe = setupUserListener(user.email, (userData) => {
            dispatch(setUser(userData));
          });

          return () => {
            userUnsubscribe();
          };
        } catch (error) {
          console.error('Error setting up user listener:', error);
          dispatch(setUserError(error.message));
        }
      } else {
        // Clear all data when user is not authenticated
        dispatch(setUser(null));
        dispatch(setActionItems([]));
        dispatch(setLeads([]));
        dispatch(setProposals([]));
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  // Set up other listeners only after user data is loaded
  useEffect(() => {
    if (!userData) return;

    let unsubscribes = [];

    try {
      dispatch(setActionItemsLoading(true));
      dispatch(setLeadsLoading(true));
      dispatch(setProposalsLoading(true));
      dispatch(setMetaLoading(true));

      const actionItemsUnsubscribe = setupActionItemsListener(userData.email, userData.role === 'admin', (items) => {
        dispatch(setActionItems(items));
      });
      unsubscribes.push(actionItemsUnsubscribe);

      const leadsUnsubscribe = setupLeadsListener(userData.email, userData.role === 'admin', (leads) => {
        dispatch(setLeads(leads));
      });
      unsubscribes.push(leadsUnsubscribe);

      const proposalsUnsubscribe = setupProposalsListener(userData.email, userData.role === 'admin', (proposals) => {
        dispatch(setProposals(proposals));
      });
      unsubscribes.push(proposalsUnsubscribe);

      // Fetch meta document
      fetchMetaDocument().then(result => {
        if (result.success) {
          dispatch(setMeta(result.data));
        } else {
          dispatch(setMetaError(result.error));
        }
      });

    } catch (error) {
      console.error('Error setting up data listeners:', error);
      dispatch(setActionItemsError(error.message));
      dispatch(setLeadsError(error.message));
      dispatch(setProposalsError(error.message));
      dispatch(setMetaError(error.message));
    }

    // Cleanup function
    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [userData, dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuSelect = (title) => {
    setPageTitle(title);
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      minHeight: '100vh',
      background: '#ffffff',
      overflow: 'hidden', // Prevent outer scroll
    }}>
      <CssBaseline />

      {/* Navbar */}
      <AppBar 
        position="fixed" 
        elevation={0}
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 2,
          height: 64,
          bgcolor: '#ffffff',
          borderBottom: '1px solid #E5E7EB',
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between', 
          px: 2,
          height: '64px' // Match AppBar height
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            width: `${drawerWidth}px`,
            position: 'absolute',
            left: 0,
            height: '100%'
          }}> 
            <Box
              component="img"
              src="https://assets.gofloaters.com/logo.png"
              alt="GoFloaters Logo"
              sx={{ height: 40, objectFit: 'contain' }}
            />
          </Box>
          <Box sx={{ 
            width: '100%',
            marginLeft: `${drawerWidth}px`
          }}>
            <Navbar onMobileMenuClick={handleDrawerToggle} pageTitle={pageTitle} />
          </Box>
        </Box>
      </AppBar>

      {/* Sidebar */}
      <Box
        component="nav"
        sx={{ 
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          position: 'relative',
          '& .MuiDrawer-paper': {
            boxShadow: 'none',
            // borderTop: '1px solid #E5E7EB',
          }
        }}
      >
        <Sidebar
          mobileOpen={mobileOpen}
          onClose={handleDrawerToggle}
          variant={isMobile ? 'temporary' : 'permanent'}
          drawerWidth={drawerWidth}
          onMenuSelect={handleMenuSelect}
        />
        {!isMobile && (
          <Divider
            orientation="vertical"
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              borderColor: '#E5E7EB'
            }}
          />
        )}
      </Box>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: '64px',
          // borderTop: '1px solid #E5E7EB',
          boxShadow: 'none',
          backgroundColor: 'rgb(249, 250, 251)',
          minHeight: 'calc(100vh - 64px)'
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
