import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, Timestamp } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import app from '../../../firebase/config';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  useTheme
} from '@mui/material';

const Login = ({ proposalId }) => {
  const [email, setEmail] = useState('');
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();
  const auth = getAuth(app);

  // Loader component for full-screen loading
  const Loader = () => (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'rgba(255, 255, 255, 0.9)',
        zIndex: 9999,
      }}
    >
      <CircularProgress size={60} />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Loading your proposal...
      </Typography>
    </Box>
  );

  // Auto-hide success message after 3 seconds
  useEffect(() => {
    let timer;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [success]);

  // Handle sign-in with email link
  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let emailFromStorage = window.localStorage.getItem('emailForSignIn');

      if (emailFromStorage) {
        setLoading(true);
        setRedirecting(true);
        signInWithEmailLink(auth, emailFromStorage, window.location.href)
          .then(async (result) => {
            const idToken = await result.user.getIdToken();
            window.localStorage.setItem('authToken', idToken);

            // Store viewer in Firestore
            try {
              if (proposalId) {
                const proposalRef = doc(db, 'proposals', proposalId);
                const proposalDoc = await getDoc(proposalRef);

                if (!proposalDoc.exists()) {
                  // Create initial document if not exists
                  await setDoc(proposalRef, {
                    viewers: [
                      {
                        email: emailFromStorage,
                        viewedAt: Timestamp.now()
                      }
                    ],
                  });
                } else {
                  // Add email to viewers array if not already present
                  await updateDoc(proposalRef, {
                    viewers: arrayUnion(emailFromStorage),
                    lastViewedAt: Timestamp.now()
                  });
                }
              }
            } catch (firestoreError) {
              console.error('Error storing viewer information:', firestoreError);
            }

            // Redirect to proposal page with proposalId
            navigate(`/public/published-proposal/?proposalId=${proposalId || ''}`);
          })
          .catch((error) => {
            console.error('Sign-in error:', error);
            setRedirecting(false);
            if (error.code === 'auth/invalid-email') {
              setError('Invalid email address. Please try again.');
            } else if (error.code === 'auth/expired-action-code') {
              setError('The sign-in link has expired. Please request a new one.');
            } else {
              setError('Error signing in. Please try again.');
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [auth, navigate, proposalId]);

  // If redirecting, show full-screen loader
  if (redirecting) {
    return <Loader />;
  }

  const handleSubmit = async () => {
    if (!email || !isChecked1 || !isChecked2) {
      setError('Please fill in all required fields');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);

      // Save email for later verification
      window.localStorage.setItem('emailForSignIn', email);

      setSuccess(true);
      setEmail('');
      setIsChecked1(false);
      setIsChecked2(false);
    } catch (error) {
      console.error('Email link error:', error);
      if (error.code === 'auth/invalid-email') {
        setError('Invalid email address. Please check and try again.');
      } else if (error.code === 'auth/quota-exceeded') {
        setError('Too many requests. Please try again later.');
      } else {
        setError('Error sending email link. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            borderRadius: 2,
            bgcolor: 'background.paper',
          }}
        >
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <img 
              src="https://assets.gofloaters.com/logo.png" 
              alt="GoFloaters"
              style={{ maxWidth: '200px', marginBottom: '8px' }}
            />
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: 'text.secondary',
                fontStyle: 'italic'
              }}
            >
              Spaces that work for you
            </Typography>
          </Box>

          <Typography
            variant="h4"
            component="h1"
            sx={{
              mb: 3,
              fontWeight: 'bold',
              color: theme.palette.primary.main,
              textAlign: 'center'
            }}
          >
            Flex Office Space Proposal
          </Typography>

          {error && (
            <Typography 
              color="error" 
              sx={{ 
                mb: 2, 
                width: '100%',
                textAlign: 'center',
                bgcolor: 'error.light',
                color: 'error.dark',
                p: 1,
                borderRadius: 1
              }}
            >
              {error}
            </Typography>
          )}

          {success && (
            <Typography 
              sx={{ 
                mb: 2, 
                width: '100%',
                textAlign: 'center',
                bgcolor: 'success.light',
                color: 'success.dark',
                p: 1,
                borderRadius: 1
              }}
            >
              Email sent successfully. Please check your email
            </Typography>
          )}

          <Box sx={{ width: '100%', mb: 3 }}>
            <TextField
              fullWidth
              type="email"
              placeholder="Email id"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              variant="outlined"
              sx={{ mb: 3 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked1}
                  onChange={(e) => setIsChecked1(e.target.checked)}
                  disabled={loading}
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  I agree to receive the proposal details and understand that my email will be used for verification purposes only.
                  The proposal information is confidential and intended solely for the recipient.
                </Typography>
              }
              sx={{ mb: 2 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked2}
                  onChange={(e) => setIsChecked2(e.target.checked)}
                  disabled={loading}
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  I understand and agree that these options were presented by GoFloaters and I grant them the authority to negotiate
                  and close the deal on my behalf.
                </Typography>
              }
              sx={{ mb: 3 }}
            />

            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={!email || !isChecked1 || !isChecked2 || loading}
              sx={{
                py: 1.5,
                fontSize: '1.1rem',
                textTransform: 'none',
                fontWeight: 'bold'
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Continue'
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Login;
