import React, { useState } from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    TextField, 
    Button, 
    Box, 
    Typography, 
    Paper, 
    IconButton,
    Fab
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { GoogleGenerativeAI } from "@google/generative-ai";
import ReactMarkdown from 'react-markdown';

// Initialize Gemini
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);
const model = genAI.getGenerativeModel({ model: "gemini-pro" });

const ChatDialog = ({ proposal, schedules }) => {
    const [chatOpen, setChatOpen] = useState(false);
    const [messages, setMessages] = useState([{
        sender: 'ai',
        text: 'Hello! How can I help you today?'}]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [lastUnansweredQuestion, setLastUnansweredQuestion] = useState('');

    const formatProposalData = () => {
        const data = {
            requirements: proposal.requirements,
            proposedOptions: proposal.proposedOptions.map(option => ({
                name: option.centerName,
                location: option.location.address,
                seats: option.billableSeats,
                price: option.pricePerSeat,
                totalRentPerMont: option.totalRentPerMont,
                operationHours: option.operationHours,
                noticePeriod: option.noticePeriod,
                lockinPeriod: option.lockinPeriod,
                operatorName: option.operatorName,
                canSupport247: option.canSupport247,
                bikeParkingCost: option.bikeParkingCost,
                carParkingCost: option.carParkingCost,
                nearestMetro: option.nearestMetro,
                inventoryDescription: option.inventoryDescription,
                securityDepositMonths: option.securityDepositMonths,
                centerName: option.centerName,
                centerDescription: option.centerDescription,
                complimentaryBikeParkSlots: option.complimentaryBikeParkSlots,
                complimentaryCarParkSlots: option.complimentaryCarParkSlots,
                complimentaryMeetingRoomCredits: option.complimentaryMeetingRoomCredits,
                customizationCost: option.customizationCost,
                amenities: option.amenities
            })),
            notes: proposal.notes.map(note => ({
                center: note.inventoryId,
                notes: note.notesBy.map(n => n.text)
            })),
            feedback: proposal.feedback?.map(fb => ({
                center: fb.inventoryId,
                questions: fb.feedbackItem.map(item => ({
                    question: item.text,
                    answer: item.answer
                }))
            })) || []
        };
        return data;
    };

    const askGoFloaters = (question) => {
        console.log('Question for GoFloaters:', question);
        // TODO: Implement actual GoFloaters question handling
    };

    const generatePrompt = (userMessage, proposalData) => {
        // Format conversation history
        const conversationHistory = messages.map(msg => 
            `${msg.sender.toUpperCase()}: ${msg.text}`
        ).join('\n');

        // Check if the current message is a response to the "ask GoFloaters" question
        const isAskingGoFloaters = messages.length > 0 && 
            messages[messages.length - 1].sender === 'ai' && 
            messages[messages.length - 1].text.includes("would you like me to ask GoFloaters?") &&
            /yes|sure|okay|ok|yep|yeah|please/i.test(userMessage);

        return `You are a helpful workspace assistant. You have access to the following workspace proposal data:

Requirements: ${proposalData.requirements}

Proposed Options:
${proposalData.proposedOptions.map(opt => 
    `- ${opt.name}:
     * Location: ${opt.location}
     * Seats: ${opt.seats}
     * Price per seat: ₹${opt.price}
     * totalRentPerMont: ₹${opt.totalRentPerMont}
     * Operating hours: ${opt.operationHours}
     * Notice Period : ${opt.noticePeriod}
     * Lockin Period : ${opt.lockinPeriod}
     * Operator Name : ${opt.operatorName}
     * Can Support 247: ${opt.canSupport247}
     * Bike Parking Cost: ${opt.bikeParkingCost}
     * Car Parking Cost: ${opt.carParkingCost}
     * Nearest Metro: ${opt.nearestMetro}
     * Inventory Description : ${opt.inventoryDescription}
     * Security Deposit in Months: ${opt.securityDepositMonths}
     * Center Name: ${opt.centerName}
     * Center Description: ${opt.centerDescription}
     * Complimentary Bike Park Slots: ${opt.complimentaryBikeParkSlots}
     * Complimentary Car Park Slots: ${opt.complimentaryCarParkSlots}
     * Complimentary Meeting Room Credits: ${opt.complimentaryMeetingRoomCredits}
     * Customization Cost: ${opt.customizationCost}
     * Amenities: ${opt.amenities.join(', ')}`
).join('\n')}

Notes:
${proposalData.notes.map(n => `- ${n.center}: ${n.notes.join(', ')}`).join('\n')}

Questions and Answers:
${proposalData.feedback.map(f => 
    f.questions.map(q => `- ${f.center}:
    Q: ${q.question}
    A: ${q.answer}`).join('\n')
).join('\n')}

Previous Conversation:
${conversationHistory}

Current Question: ${userMessage}
Is Responding to Ask GoFloaters: ${isAskingGoFloaters}
Last Unanswered Question: ${lastUnansweredQuestion}

Instructions for response:
1. If you can answer the question based on the provided data, give a clear and concise response.
2. If you cannot answer the question because the information is not in the provided data:
   - For new questions: Respond with "I don't know, would you like me to ask GoFloaters?" and set this as the last unanswered question
   - For affirmative responses to the above question: Extract a complete question (which is comprehensive) from the conversation history that was unanswered the latest and Respond with "[ASK_GOFLOATERS]" + the question + [/ASK_GOFLOATERS] OK."
3. Always maintain a helpful and professional tone.
4. Format your responses in markdown for better readability.`;
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;

        const userMessage = {
            text: newMessage,
            sender: 'user',
            timestamp: new Date()
        };

        setMessages(prev => [...prev, userMessage]);
        setNewMessage('');
        setLoading(true);

        try {
            const proposalData = formatProposalData();
            
            // If this is a new question (not a response to "ask GoFloaters")
            if (!messages.length || 
                !messages[messages.length - 1].text.includes("would you like me to ask GoFloaters?")) {
                setLastUnansweredQuestion(newMessage);
            }

            const prompt = generatePrompt(newMessage, proposalData);
            console.log('Prompt:', prompt);
            
            // Generate response using Gemini
            const result = await model.generateContent(prompt);
            const response = await result.response;
            const text = response.text();

            // Check if the response contains a GoFloaters question
            if (text.includes('[ASK_GOFLOATERS]')) {
                const question = text.match(/\[ASK_GOFLOATERS\](.*?)\[\/ASK_GOFLOATERS\]/)[1];
                askGoFloaters(question);
            }

            const aiResponse = {
                text: text.replace(/\[ASK_GOFLOATERS\].*?\[\/ASK_GOFLOATERS\]/g, ''),
                sender: 'ai',
                timestamp: new Date()
            };
            
            setMessages(prev => [...prev, aiResponse]);
        } catch (error) {
            console.error('Error generating response:', error);
            const errorResponse = {
                text: "I apologize, but I'm having trouble processing your request at the moment. Please try again.",
                sender: 'ai',
                timestamp: new Date()
            };
            setMessages(prev => [...prev, errorResponse]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Fab
                color="primary"
                aria-label="chat"
                onClick={() => setChatOpen(true)}
                sx={{
                    position: 'fixed',
                    bottom: 24,
                    right: 24
                }}
            >
                <ChatIcon />
            </Fab>
            <Dialog
                open={chatOpen}
                onClose={() => setChatOpen(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        height: '80vh',
                        maxHeight: '700px'
                    }
                }}
            >
                <DialogTitle sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    borderBottom: '1px solid #e0e0e0'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ChatIcon color="primary" />
                        <Typography>Proposal Assistant</Typography>
                    </Box>
                    <IconButton onClick={() => setChatOpen(false)} size="small">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ flexGrow: 1, overflow: 'auto', mb: 2 }}>
                        {messages.map((message, index) => (
                            <Paper
                                key={index}
                                sx={{
                                    p: 2,
                                    mt : 2,
                                    borderTopRightRadius: message.sender === 'user' ? 0 : 20,
                                    borderTopLeftRadius: message.sender === 'ai' ? 0 : 20,
                                    borderBottomRightRadius: 20,
                                    borderBottomLeftRadius: 20,
                                    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
                                    maxWidth: '80%',
                                    ml: message.sender === 'user' ? 'auto' : 0,
                                    mr: message.sender === 'ai' ? 'auto' : 0,
                                    bgcolor: message.sender === 'user' ? 'grey.50' : 'grey.100',
                                    '& .markdown-content': {
                                        '& p': {
                                            m: 0,
                                            mb: 1,
                                            '&:last-child': {
                                                mb: 0
                                            }
                                        },
                                        '& ul, & ol': {
                                            m: 0,
                                            mb: 1,
                                            pl: 2
                                        },
                                        '& h1, & h2, & h3, & h4, & h5, & h6': {
                                            fontSize: 'inherit',
                                            fontWeight: 'bold',
                                            m: 0,
                                            mb: 1
                                        },
                                        '& code': {
                                            p: 0.5,
                                            borderRadius: 1,
                                            bgcolor: 'action.hover',
                                            fontSize: '0.875rem'
                                        },
                                        '& pre': {
                                            p: 1,
                                            borderRadius: 1,
                                            bgcolor: 'action.hover',
                                            overflowX: 'auto'
                                        },
                                        '& blockquote': {
                                            m: 0,
                                            mb: 1,
                                            pl: 1,
                                            borderLeft: '4px solid',
                                            borderColor: 'divider'
                                        }
                                    }
                                }}
                            >
                                <Box className="markdown-content">
                                    {message.sender === 'user' ? (
                                        <Typography>{message.text}</Typography>
                                    ) : (
                                        <ReactMarkdown>{message.text}</ReactMarkdown>
                                    )}
                                </Box>
                            </Paper>
                        ))}
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                                <Typography color="text.secondary">Thinking...</Typography>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Ask about workspaces..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        sx={{ mr: 1 }}
                    />
                    <Button
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={handleSendMessage}
                        disabled={!newMessage.trim() || loading}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ChatDialog;
