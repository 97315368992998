import { GoogleGenerativeAI } from "@google/generative-ai";
import OpenAI from 'openai';

// Flag to switch between APIs
const USE_OPENAI = true;

// Initialize APIs
const geminiAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);
const openAI = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const extractJSONFromText = (text) => {
  try {
    // Try to parse the entire text as JSON first
    return JSON.parse(text);
  } catch (e) {
    // If that fails, try to find JSON within the text
    const jsonMatch = text.match(/\{[\s\S]*\}/);
    if (jsonMatch) {
      try {
        return JSON.parse(jsonMatch[0]);
      } catch (e2) {
        console.error('Error parsing extracted JSON:', e2);
        throw new Error('Invalid JSON format in response');
      }
    }
    throw new Error('No valid JSON found in response');
  }
};

const generateWithOpenAI = async (prompt) => {
  const response = await openAI.chat.completions.create({
    model: "gpt-3.5-turbo-1106", // Using GPT-3.5 Turbo with JSON mode
    messages: [
      {
        role: "system",
        content: "You are a professional data analyst that returns information in markdown format."
      },
      {
        role: "user",
        content: `${prompt}\n\nReturn the response in markdown format.`
      }
    ],
    temperature: 0.1
  });

  const content = JSON.parse(response.choices[0].message.content);
  return content.markdown;
};

const generateWithGemini = async (prompt) => {
  const model = geminiAI.getGenerativeModel({ model: "gemini-pro" });
  const result = await model.generateContent(prompt + "\n\nProvide ONLY the Markdown formatted content.");
  const response = await result.response;
  const text = response.text();
  return text;
  // return extractJSONFromText(text);
};

export const generateProfile = async (prompt) => {
  try {
    if (USE_OPENAI) {
      try {
        return await generateWithOpenAI(prompt);
      } catch (openAIError) {
        console.warn('OpenAI error, falling back to Gemini:', openAIError);
        return await generateWithGemini(prompt);
      }
    } else {
      return await generateWithGemini(prompt);
    }
  } catch (error) {
    console.error('Error generating profile:', error);
    throw new Error(`Failed to generate profile: ${error.message}`);
  }
};
